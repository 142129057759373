import styled from 'styled-components'
import { NumericFormat } from 'react-number-format'

export const modalStyle = {
  overlay: {
    zIndex: 5
  },
  content: {
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    zIndex: 5,
    overflow: 'visible',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 15,
    boxShadow: '0px 2px 15px -8px #252525'
  }
}

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  width: 960px;
  min-width: 840px;
  background-color: #F7F7F7;
  padding: 10px;
  border-radius: 15px;
`

export const InfoColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
  padding: 3px 10px;
`

export const BillColumn = styled(InfoColumn)`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0px;
`

export const Row = styled.div`
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 14px;
  margin-bottom: 0px;
`

export const Title = styled(InfoText)`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
`

export const SubTitle = styled(Title)`
  font-size: 14px;
  margin-bottom: 0px;
`

export const MoneyTextMask = styled(NumericFormat).attrs (props => ({
  value: props.value.toFixed (2).replace ('.', ','),
  thousandSeparator: '.',
  decimalSeparator: ',',
  displayType: 'text',
  prefix: 'R$ '
}))`
  color: #44CF6C;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  padding: 0px;
`

export const CloseButton = styled.button`
  position: fixed;
  top: 5px;
  right: 10px;
  width: 30px;
  height: 38px;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`

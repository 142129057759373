import React from 'react'
import NumberInput from '../../Inputs/NumberInput'
import MoneyInput from '../../Inputs/MoneyInput'
import CheckBox from '../../CheckBox'

import {
  Container,
  DataGrid,
  FormGrid,
  FormField,
  Row,
  Input,
  SubTitle,
  InfoText
} from './styles'

function ProductForm ({
  data: product,
  index,
  formikProps
}) {
  const { values, setFieldValue, handleChange, handleBlur } = formikProps
  const selected = values.products[index]?.selected

  return (
    <Container selected={selected}>
      <DataGrid onClick={() => setFieldValue (`products[${index}].selected`, !selected)}>
        <Row>
          <CheckBox
            id={`products[${index}].selected`}
            value={values.products[index]?.selected}
            onValueChange={value => setFieldValue (`products[${index}].selected`, value)}
          />
          <SubTitle>
            {product.name}
          </SubTitle>
        </Row>
        <InfoText>
          {product.category.name}
        </InfoText>
      </DataGrid>
      {selected && (
        <FormGrid>
          <FormField>
            <InfoText style={{marginBottom: 3}}>
              Apelido
            </InfoText>
            <Input
              placeholder='Apelido (opcional)'
              value={values.products[index]?.nickname}
              onChange={handleChange (`products.${index}.nickname`)} 
              onBlur={handleBlur (`products.${index}.nickname`)}
              style={{marginTop: 0}}
            />
          </FormField>
          <FormField>
            <InfoText>
              Qtd. estoque
            </InfoText>
            <NumberInput
              name={`products.${index}.stock`}
              value={values.products[index]?.stock}
              minValue={1}
              onChange={(name, value) => setFieldValue (name, value)}
            />
          </FormField>
          <FormField>
            <InfoText style={{marginBottom: 3}}>
              Preço
            </InfoText>
            <MoneyInput
              name={`products[${index}].price`}
              placeholder='(R$)'
              value={values.products[index]?.price}
              onValueChange={setFieldValue}
              onBlur={handleBlur (`products.${index}.price`)}
            />
          </FormField>
          <FormField>
            <InfoText>
              % Comissão
            </InfoText>
            <NumberInput
              name={`products.${index}.comission_percentage`}
              value={values.products[index]?.comission_percentage}
              minValue={0}
              maxValue={100}
              onChange={(name, value) => setFieldValue (name, value)}
            />
          </FormField>
        </FormGrid>
      )}
    </Container>
  )
}

export default ProductForm

import styled from 'styled-components'
import { NumericFormat } from 'react-number-format'

export const Container = styled.div`
  background-color: ${({editting}) => editting ? '#F7F7F7' : '#FFFFFF'};
  width: 100%;
  height: ${({editting}) => editting? '51px' : 'auto'};
  margin: 0px 0px 10px;
  box-shadow: 0px 2px 10px -6px #252525;
  text-decoration: none;
  border-radius: 15px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding: 3px 12px;

  &:hover {
    background-color: #F7F7F7;
  }
`

export const FormField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const InfoText = styled.p`
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin: 0px;
`

export const SubTitle = styled(InfoText)`
  font-weight: bold;
  text-align: left;
`

export const MoneyTextMask = styled(NumericFormat).attrs (props => ({
  value: props.value.toFixed (2).replace ('.', ','),
  thousandSeparator: '.',
  decimalSeparator: ',',
  displayType: 'text',
  prefix: 'R$ '
}))`
  color: ${props => parseFloat (props.value) >= 0 ? '#44CF6C' : '#FF3939'};
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  padding: 0px;
`

export const Button = styled.button.attrs ({
  type: 'button'
})`
  width: 32px;
  height: 32px;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    background-color: #F0F0F0;
    box-shadow: 0px 2px 10px -6px #252525;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

import React, { useState } from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import AuthService from '../../services/auth'
import cutileiApi from '../../services/cutileiApi'
import cutileiLogo from '../../icons/logo-cutilei-simple.png'
import ReactTooltip from 'react-tooltip'
import * as IO from 'react-icons/io5'
import * as FA from 'react-icons/fa'
import { IconContext } from 'react-icons'

import {
  Container,
  Image,
  NavItem,
  Label
} from './styles'

function Navbar ({ history }) {
  const [loading, setLoading] = useState (false)
  const signed = AuthService.isSigned ()
  const isBusiness = AuthService.isBusiness ()

  const handleSignOut = async () => {
    try {
      setLoading (true)
      await cutileiApi.delete ('/auth/businesses', {
        headers: {
          'Authorization': `Bearer ${AuthService.getToken ()}`
        }
      })
      setLoading (false)
      AuthService.signOut ()
      history.replace ('/')
    } catch (error) {
      setLoading (false)
      console.log (error.response.data)
      if (error.response.status === 401) {
        AuthService.signOut ()
        history.replace ('/')
      }
    }
  }

  return (
    <IconContext.Provider value={{color: '#252525'}}>
      <Container>
        <Image src={cutileiLogo} alt='Cutilei'/>
        {signed ? (
          <>
            <NavItem>
              <NavLink
                to='/business'
                activeClassName='active'
                exact
                data-for='agenda'
                data-tip
              >
                <IO.IoCalendar/>
              </NavLink>
              <ReactTooltip id='agenda' effect='solid' place='right' backgroundColor='#252525' tooltipRadius='10'>
                <Label style={{color: '#FFFFFF'}}>
                  Agenda
                </Label>
              </ReactTooltip>
            </NavItem>
            {isBusiness &&
              <NavItem>
                <NavLink
                  to='/business/financial'
                  activeClassName='active'
                  exact
                  data-for='financial'
                  data-tip
                >
                  <FA.FaDollarSign/>
                </NavLink>
                <ReactTooltip id='financial' effect='solid' place='right' backgroundColor='#252525' tooltipRadius='10'>
                  <Label style={{color: '#FFFFFF'}}>
                    Financeiro
                  </Label>
                </ReactTooltip>
              </NavItem>
            }
            <NavItem>
              <NavLink
                to='/business/customers'
                activeClassName='active'
                exact
                data-for='customers'
                data-tip
              >
                <FA.FaUser/>
              </NavLink>
              <ReactTooltip id='customers' effect='solid' place='right' backgroundColor='#252525' tooltipRadius='10'>
                <Label style={{color: '#FFFFFF'}}>
                  Clientes
                </Label>
              </ReactTooltip>
            </NavItem>
            <NavItem>
              <NavLink
                to='/business/schedules'
                activeClassName='active'
                exact
                data-for='schedule_history'
                data-tip
              >
                <IO.IoReader/>
              </NavLink>
              <ReactTooltip id='schedule_history' effect='solid' place='right' backgroundColor='#252525' tooltipRadius='10'>
                <Label style={{color: '#FFFFFF'}}>
                  Histórico de agendamentos
                </Label>
              </ReactTooltip>
            </NavItem>
            <NavItem>
              <NavLink
                to='/business/config'
                activeClassName='active'
                exact
                data-for='config'
                data-tip
              >
                <FA.FaCog/>
              </NavLink>
              <ReactTooltip id='config' effect='solid' place='right' backgroundColor='#252525' tooltipRadius='10'>
                <Label style={{color: '#FFFFFF'}}>
                  Configurações
                </Label>
              </ReactTooltip>
            </NavItem>
            <NavItem>
              <a
                href='https://wa.me/message/SH5AWAQ6UP4IF1'
                target='_blank'
                data-for='contact'
                data-tip
              >
                <FA.FaQuestion/>
              </a>
              <ReactTooltip id='contact' effect='solid' place='right' backgroundColor='#252525' tooltipRadius='10'>
                <Label style={{color: '#FFFFFF'}}>
                  Dúvidas? Fale conosco
                </Label>
              </ReactTooltip>
            </NavItem>
            <NavItem>
              <Link to='/' onClick={handleSignOut} data-for='logout' data-tip>
                <FA.FaSignOutAlt/>
              </Link>
              <ReactTooltip id='logout' effect='solid' place='right' backgroundColor='#252525' tooltipRadius='10'>
                <Label style={{color: '#FFFFFF'}}>
                  Sair
                </Label>
              </ReactTooltip>
            </NavItem>
          </>
          ) : (
            <>
              <NavItem>
                <NavLink to='/login' activeClassName='active'>
                  <FA.FaSignInAlt/>
                </NavLink>
              </NavItem>
              <NavItem>
                <a
                  href='https://wa.me/message/SH5AWAQ6UP4IF1'
                  target='_blank'
                  data-for='contact'
                  data-tip
                >
                  <FA.FaQuestion/>
                </a>
                <ReactTooltip id='contact' effect='solid' place='right' backgroundColor='#252525' tooltipRadius='10'>
                  <Label style={{color: '#FFFFFF'}}>
                    Dúvidas? Fale conosco
                  </Label>
                </ReactTooltip>
              </NavItem>
            </>
          )
        }
      </Container>
    </IconContext.Provider>
  )
}

export default withRouter (Navbar)

import React, { useState, useEffect } from 'react'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import Navbar from '../../../components/Navbar'
import AlertDialog from '../../../components/AlertDialog'
import Select from '../../../components/Inputs/Select'
import ClearableInput from '../../../components/Inputs/ClearableInput'
import BusinessServiceList from '../../../components/Lists/BusinessServiceList'
import ProfessionalServicesModal from '../../Modals/ServicesModal/Professional'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'

import {
  Container,
  Label,
  Button,
  Row
} from '../styles'

function EditProfessionalServices ({
  professional,
  professionalCategories: categories,
  professionalWorkingDays,
  onCategoriesUpdated: handleCategoriesUpdated,
  onWorkingDaysUpdated: handleWorkingDaysUpdated
}) {
  require ('cleave.js/dist/addons/cleave-phone.br')

  const [loading, setLoading] = useState (true)
  const [submitting, setSubmitting] = useState (false)
  const [services, setServices] = useState ([])
  const [serviceCategories, setServiceCategories] = useState ([])
  const [professionalCategories, setProfessionalCategories] = useState (categories)
  const [categoryToRemove, setCategoryToRemove] = useState (null)
  const [showServicesModal, setShowServicesModal] = useState (false)
  const [showRemoveDialog, setShowRemoveDialog] = useState (false)
  const [showErrorDialog, setShowErrorDialog] = useState (false)
  const [category, setCategory] = useState (null)
  const [name, setName] = useState ('')
  const [orderBy, setOrderBy] = useState ('')
  const token = AuthService.getToken ()

  useEffect (() => {
    getData ()
  }, [professional])

  useEffect (() => {
    getServices ()
  }, [category, name])

  useEffect (() => {
    if (!loading) handleCategoriesUpdated (professionalCategories)
    getServices ()
  }, [professionalCategories])

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const getData = async () => {
    try {
      const { data: serviceCategories } = await cutileiApi.get (
        '/service_categories', requestConfig
      )

      setServiceCategories (serviceCategories)
      await getServices ()
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const getServices = async () => {
    let filters = ''
    if (name) filters += `&name=${name}`
    if (category) filters += `&category_id=${category.value}`
    else filters += `&categories=${professionalCategories?.map (pc => pc.id)?.join (',')}`
    if (orderBy) filters += `&order_by=${orderBy}`

    try {
      setLoading (true)

      const { data: services } = await cutileiApi.get (
        `/professionals/${professional?.id}/services?status=active${filters}`, requestConfig
      )

      setServices (services)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const editServiceCategories = async categories => {
    try {
      setSubmitting (true)

      await cutileiApi.post (
        `/professionals/${professional?.id}/service_categories`, {
          service_categories: categories.map (c => c.value)
        }, requestConfig
      )

      setProfessionalCategories (categories.map (c => c.value))
    } catch (error) {
      console.log (error)
    } finally {
      setSubmitting (false)
    }
  }

  const removeServiceCategory = async category => {
    try {
      setSubmitting (true)

      await cutileiApi.delete (
        `/professionals/${professional?.id}/service_categories/${category.id}`,
        requestConfig
      )
      
      if (category.name.toLowerCase () === 'manicure e pedicure') {
        const editedWorkingDays = professionalWorkingDays.map (day => ({...day, accepts_cutilei: false}))

        await cutileiApi.post (`/professionals/${professional.id}/working_days`, {
          working_days: editedWorkingDays
        }, requestConfig)

        handleWorkingDaysUpdated (editedWorkingDays)
      }

      setProfessionalCategories (professionalCategories.filter (c => c.id !== category.id))
    } catch (error) {
      console.log (error)
    } finally {
      setSubmitting (false)
    }
  }

  const handleServicesAdded = (addedServices, serviceCategories) => {
    setServices ([...services, ...addedServices].sort (compareServices))
    setProfessionalCategories (serviceCategories)
  }

  const handleServiceDeleted = async deletedService => {
    const updatedServices = services.filter (s => s.id !== deletedService.id)
    const serviceCategory = deletedService.category

    setServices (updatedServices)

    if (!updatedServices.some (s => s.category.id === serviceCategory.id))
      removeServiceCategory (serviceCategory)
  }

  const compareServices = (a, b) => a.number - b.number
  const toggleServicesModal = () => setShowServicesModal (!showServicesModal)
  const toggleRemoveDialog = () => setShowRemoveDialog (!showRemoveDialog)
  const toggleErrorDialog = () => setShowErrorDialog (!showErrorDialog)

  return (
    <Container style={{paddingTop: 0}}>
      <Navbar/>
      <Label style={{marginBottom: 5}}>
        Categorias de serviço
      </Label>
      <Row>
        <Select
          name='service_categories'
          placeholder='Selecionar categorias...'
          value={professionalCategories.map (c => ({label: c.name, value: c}))} 
          options={serviceCategories.map (c => ({label: c.name, value: c}))}
          isMulti={true}
          onChange={(_, options) => editServiceCategories (options)}
          onRemoveOption={option => {
            if (services.some (s => s.category.id === option.value.id && !s.cutilei_service)) {
              toggleErrorDialog ()
            } else {
              setCategoryToRemove (option.value)
              toggleRemoveDialog ()
            }
          }}
          containerStyles={{boxShadow: '0px 1px 10px -6px', borderRadius: 15, width: 600}}
        />
      </Row>
      <Row style={{gap: 10, width: 600}}>
        <Select
          name='category'
          placeholder='Categoria...'
          value={category}
          options={professionalCategories.map (category => ({
            label: category.name,
            value: category.id
          }))}
          isClearable={true}
          onChange={(_, option) => setCategory (option)}
          containerStyles={{boxShadow: '0px 1px 10px -7px', borderRadius: 15, height: 38}}
        />
        <ClearableInput
          placeholder='Pesquisar por nome'
          value={name}
          onChange={e => setName (e.target.value)}
          onClear={() => setName ('')}
          inputStyle={{boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
        />
      </Row>
      <Button style={{marginBottom: 20}} onClick={toggleServicesModal}>
        Adicionar serviços
      </Button>
      {loading ? <Loading style={{marginBottom: 20}}/> : (
        <div style={{marginBottom: 15}}>
          <BusinessServiceList
            services={services}
            professionalID={professional?.id}
            onEdit={service => setServices (services.map (s => s.id === service.id ? {...service} : s))}
            onDelete={handleServiceDeleted}
          />
          <ProfessionalServicesModal
            visible={showServicesModal}
            title='Adicionar serviços'
            confirmText='Confirmar'
            professionalID={professional?.id}
            serviceCategories={serviceCategories}
            professionalCategories={professionalCategories}
            onConfirm={handleServicesAdded}
            onClose={toggleServicesModal}
          />
        </div>
      )}
      {categoryToRemove && (
        <AlertDialog
          visible={showRemoveDialog}
          title='Atenção!'
          message='Deseja realmente remover esta categoria deste profissional?'
          confirmText='Sim'
          cancelText='Não'
          onConfirm={() => removeServiceCategory (categoryToRemove)}
          onClose={toggleRemoveDialog}
          dangerous
        />
      )}
      <AlertDialog
        visible={showErrorDialog}
        title='Atenção!'
        message='Para remover esta categoria, remova do profissional os serviços dela primeiro'
        confirmText='Ok'
        onConfirm={toggleErrorDialog}
        onClose={toggleErrorDialog}
      />
    </Container>
  )
}

export default EditProfessionalServices

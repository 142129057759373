import React, { useState } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import MoneyInput from '../../../components/Inputs/MoneyInput'
import { Formik } from 'formik'
import earningAndExpenseValidator from '../../../validators/earningAndExpenseValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Form,
  FormField,
  Title,
  Input,
  Button,
  CloseButton,
  Row,
  InfoText,
  WarningText,
  ErrorContainer
} from './styles'

function AddEarningModal ({
  visible,
  cashRegister,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const user = AuthService.getUser ()
  const token = AuthService.getToken ()
  const isBusiness = AuthService.isBusiness ()
  const businessId = isBusiness ? user.id : user.business_id

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={earningAndExpenseValidator}
        initialValues={{
          'value': 0,
          'description': ''
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const { data: earning } = await cutileiApi.post ('/earnings', {
              ...values,
              businessId: businessId,
              cashRegisterId: cashRegister.id
            }, requestConfig)

            setSubmitting (false)
            handleConfirm (earning)
            handleClose ()
          } catch (error) {
            if (error.response.data) setErrorMessage (error.response.data.message)
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Title>Novo suprimento</Title>
            <CloseButton onClick={handleClose}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </CloseButton>
            <Row>
              <FormField>
                <MoneyInput
                  name='value'
                  placeholder='Valor'
                  value={values.value}
                  onValueChange={setFieldValue}
                  onBlur={handleBlur ('value')}
                />
                {touched.value && errors.value && (
                  <WarningText>
                    {errors.value}
                  </WarningText>
                )}
              </FormField>
              <FormField>
                <Input 
                  placeholder='Descrição do suprimento'
                  value={values.description}
                  onChange={handleChange ('description')} 
                  onBlur={handleBlur ('description')}
                />
                {touched.description && errors.description && (
                  <WarningText>
                    {errors.description}
                  </WarningText>
                )}
              </FormField>
            </Row>

            {errorMessage && (
              <ErrorContainer>
                <InfoText>{errorMessage}</InfoText>
              </ErrorContainer>
            )}

            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Adicionar suprimento'}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default AddEarningModal

import React from 'react'
import NumberInput from '../../Inputs/NumberInput'
import MoneyInput from '../../Inputs/MoneyInput'
import Select from '../../Inputs/Select'
import * as FA from 'react-icons/fa'

import {
  Form,
  FieldGrid,
  FormField,
  Row,
  Input,
  InfoText,
  WarningText,
  MoneyTextMask
} from './styles'

function PaymentForm ({
  index,
  formikProps,
  paymentMethods,
  bankAccounts,
  cardFlags,
  billValue
}) {
  const {
    values, touched, errors, handleChange, setFieldValue, setFieldTouched, handleBlur
  } = formikProps
  const paymentMethodsWithDescription = ['voucher', 'courtesy', 'exchange']
  const paymentMethodsWithBankAccount = ['pix', 'transfer', 'billet']
  const cardPaymentMethods = ['credit_card', 'debit_card']

  const handlePaymentMethodSelected = (name, option) => {
    if (option?.value.codename !== 'credit_card') {
      setFieldValue (`payments[${index}].cardFlag`, undefined)
      setFieldValue (`payments[${index}].installments`, undefined)
      setFieldValue (`payments[${index}].installmentValue`, undefined)
    } else {
      setFieldValue (`payments[${index}].installments`, 1)
    }

    if (!paymentMethodsWithBankAccount.includes (option?.value.codename)) 
      setFieldValue (`payments[${index}].bankAccount`, undefined)
    if (!paymentMethodsWithDescription.includes (option?.value.codename)) 
      setFieldValue (`payments[${index}].description`, '')

    if (index === 0 && values.payments.length === 1)
      setFieldValue (`payments[${index}].value`, billValue)
    setFieldValue (name, option)
  }

  return (
    <Form>
      <FieldGrid index={index} values={values}>
        <FormField>
          {touched.payments?.[index]?.paymentMethod && errors.payments?.[index]?.paymentMethod && (
            <WarningText>{errors.payments[index].paymentMethod}</WarningText>
          )}
          <Select
            name={`payments[${index}].paymentMethod`}
            placeholder='Método de pagamento...'
            value={values.payments[index]?.paymentMethod ? {
              label: values.payments[index]?.paymentMethod.label,
              value: values.payments[index]?.paymentMethod.value
            }: null}
            options={paymentMethods.map (paymentMethod => ({
              label: paymentMethod.name,
              value: paymentMethod
            }))}
            onChange={handlePaymentMethodSelected}
            onBlur={setFieldTouched}
          />
        </FormField>
        {cardPaymentMethods.includes (
          values.payments[index]?.paymentMethod?.value.codename
        ) && (
          <FormField>
            {touched.payments?.[index]?.cardFlag && errors.payments?.[index]?.cardFlag && (
              <WarningText>{errors.payments[index].cardFlag}</WarningText>
            )}
            <Select
              name={`payments[${index}].cardFlag`}
              placeholder='Bandeira...'
              value={values.payments[index]?.cardFlag ? {
                label: values.payments[index]?.cardFlag.label,
                value: values.payments[index]?.cardFlag.value
              }: null}
              options={
                cardFlags.filter (cardFlag =>
                  values.payments[index]?.paymentMethod?.value.codename.includes (cardFlag.type)
                  || cardFlag.type === 'all'
                ).map (cardFlag => ({
                  label: cardFlag.name,
                  value: cardFlag.id
                }))
              }
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
          </FormField>
        )}
        {paymentMethodsWithDescription.includes (
          values.payments[index]?.paymentMethod?.value.codename
        ) && (
          <Input
            type='text'
            placeholder='Descrição'
            value={values.payments[index]?.description}
            onChange={handleChange (`payments[${index}].description`)} 
            onBlur={handleBlur (`payments[${index}].description`)}
          />
        )}
        {paymentMethodsWithBankAccount.includes (
          values.payments[index]?.paymentMethod?.value.codename
        ) && (
          <Select
            name={`payments[${index}].bankAccount`}
            placeholder='Conta bancária...'
            value={values.payments[index]?.bankAccount ? {
              label: values.payments[index]?.bankAccount.label,
              value: values.payments[index]?.bankAccount.value
            }: null}
            options={
              bankAccounts.map (bankAccount => ({
                label: `(${bankAccount.bank.code}) ${bankAccount.bank.name} - ${bankAccount.description}`,
                value: bankAccount
              }))
            }
            onChange={setFieldValue}
            onBlur={setFieldTouched}
          />
        )}
        <FormField>
          <MoneyInput
            name={`payments[${index}].value`}
            placeholder='Valor'
            value={values.payments[index]?.value}
            onValueChange={(name, value) => {
              setFieldValue (name, value)
              setFieldValue (`payments[${index}].installmentValue`, value)
            }}
            onBlur={handleBlur (`services[${index}].value`)}
          />
        </FormField>
        {values.payments[index]?.paymentMethod?.value.codename === 'credit_card' && (
          <>
            <Row>
              <FormField>
                <InfoText style={{color: '#000000'}}>
                  Parcelas:
                </InfoText>
                <NumberInput
                  name={`payments[${index}].installments`}
                  placeholder='Parcelas'
                  value={values.payments[index]?.installments}
                  minValue={1}
                  maxValue={36}
                  onChange={(name, value) => {
                    setFieldValue (name, value)
                    setFieldValue (`payments[${index}].installmentValue`, values.payments[index]?.value / value)
                  }}
                  style={{width: '100%', marginLeft: -8, marginBottom: -4}}
                />
              </FormField>
            </Row>
            <Row>
              <FA.FaTimes color='#000000' size={14} style={{marginBottom: 3}}/>
              <MoneyTextMask
                value={(values.payments[index]?.value / values.payments[index]?.installments)}
                style={{marginBottom: 5}}
              />
            </Row>
          </>
        )}
      </FieldGrid>
    </Form>
  )
}

export default PaymentForm

import React, { useState, useEffect } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import ScheduleList from '../../../../components/Lists/ScheduleList'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'

import {
  Container,
} from '../styles'

function CustomerSchedules ({ customer }) {
  const [loading, setLoading] = useState (true)
  const [page, setPage] = useState (1)
  const [lastPage, setLastPage] = useState (0)
  const [total, setTotal] = useState (0)
  const [schedules, setSchedules] = useState ([])
  const token = AuthService.getToken ()

  useEffect (() => {
    getSchedules (1)
  }, [])

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const getSchedules = async (pageNumber = page) => {
    if (lastPage && pageNumber > lastPage) return
    const filters = `&page=${pageNumber}&per_page=25&order=desc&canceled=all`

    try {
      const { data: schedulesResponse } = await cutileiApi.get (
        `/schedules?customer_id=${customer.id}${filters}`, requestConfig
      )

      const { data, meta } = schedulesResponse

      pageNumber > 1 ? setSchedules ([...schedules, ...data]) : setSchedules (data)
      setPage (pageNumber + 1)
      setLastPage (meta.last_page)
      setTotal (meta.total)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Container style={{paddingInline: 10}}>
      {loading ? <Loading style={{marginBottom: 20}}/> : (
        <ScheduleList
          schedules={schedules}
          totalSchedules={total}
          hasMore={!(lastPage && page > lastPage)}
          onScroll={() => getSchedules ()}
          detailed
        />
      )}
    </Container>
  )
}

export default CustomerSchedules

import * as Yup from 'yup'
import { isValid as isValidCnpj, strip } from "@fnando/cnpj"
import cutileiApi from '../services/cutileiApi'
import Auth from '../services/auth'

const isNonExistingUser = async (key, value) => {
  const business = Auth.getUser ()
  const query = business ? `?business_id=${business.id}` : ''

  try {
    await cutileiApi.post (`/businesses/verify_existing${query}`, {
      [key]: value
    })
    return true
  } catch (error) {
    return false
  }
}

const phoneRegex = /\(|\)| |-/g
const postalCodeRegex = (/^(\d{5})(\d{3})$/)

const signUpValidator = Yup.object ({
  name: Yup.string ()
    .required ('Digite um nome')
    .min (4, 'O nome deve conter no minimo 4 caracteres')
    .max (128, 'O nome deve conter até 128 caracteres'),
  
  corporate_name: Yup.string ()
    .required ('Digite um nome')
    .min (4, 'O nome deve conter no minimo 4 caracteres')
    .max (128, 'O nome deve conter até 128 caracteres')
    .test ('newCorporateName', 'Esta razão social já foi cadastrada', async (value) => {
      if (value) {
        return isNonExistingUser ('corporate_name', value)
      }
    }),

  cnpj: Yup.string ()
    .required ('Digite seu CNPJ')
    .test ('validCnpj', 'Digite um CNPJ válido', function (value) {
      return isValidCnpj (value)
    })
    .test ('newCnpj', 'Este CNPJ já foi cadastrado', async (value) => {
      if (value) {
        return isNonExistingUser ('cnpj', strip (value))
      }
    }),
    

  email: Yup.string ()
    .required ('Digite um email')
    .email ('Insira um email válido')
    .max (128, 'O e-mail deve conter até 128 caracteres')
    .test ('newEmail', 'Este endereço de e-mail já foi cadastrado', async (value) => {
      if (value) {
        return isNonExistingUser ('email', value)
      }
    }),
    
  phone: Yup.string ()
    .test ('validPhone', 'Insira um telefone válido', function (value) {
      if (!value) return true
      return value.replace (phoneRegex, '').length === 10
    })
    .test ('newPhone', 'Este número de telefone já foi cadastrado', async (value) => {
      if (value) {
        return isNonExistingUser ('phone', value.replace (phoneRegex, ''))
      }
      return true
    }),

  cellphone: Yup.string ()
    .required ('Digite o número de celular')
    .test ('validCellphone', 'Insira um celular válido', function (value) {
      if (!value) return true
      return value.replace (phoneRegex, '').length === 11
    })
    .test ('newCellphone', 'Este número de celular já foi cadastrado', async (value) => {
      if (value) {
        return isNonExistingUser ('cellphone', value.replace (phoneRegex, ''))
      }
      return true
    }),

  owner: Yup.string ()
    .required ('Digite um nome')
    .min (4, 'O nome deve conter no minimo 4 caracteres')
    .max (128, 'O nome deve conter até 128 caracteres'),
    
  owner_email: Yup.string ()
    .required ('Digite o email do responsável')
    .email ('Insira um email válido')
    .max (128, 'O e-mail deve conter até 128 caracteres'),

  owner_phone: Yup.string ()
    .required ('Digite o número de celular')
    .test ('validCellphone', 'Insira um celular válido', function (value) {
      if (!value) return true
      return value.replace (phoneRegex, '').length === 11
    }),

  password: Yup.string ()
    .required ('Digite uma senha')
    .min (8, 'A senha deve conter no mínimo 8 caracteres'),

  passwordConfirmation: Yup.string ()
    .required ('Confirme a senha')
    .oneOf ([Yup.ref ('password'), null], 'As duas senhas devem ser iguais'),

  postal_code: Yup
    .string ()
    .required ('Digite o CEP')
    .test ('validPostalCode', 'Digite um CEP válido', function (value) {
      return postalCodeRegex.test (value)
    }),

  number: Yup
    .string ()
    .required ('Digite o número da residência')
    .max (5, 'O numero deve ter no máximo 5 digitos'),

  complement: Yup.string ()
})

export default signUpValidator

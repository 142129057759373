import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'

export const Container = styled(Link)`
  background-color: #FFFFFF;
  width: 100%;
  margin: 0px 0px 10px;
  box-shadow: 0px 2px 10px -6px #252525;
  text-decoration: none;
  border-radius: 15px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 7px 12px;

  &:hover {
    background-color: #F7F7F7;
  }
`

export const InfoText = styled.p`
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin: 0px;
`

export const SubTitle = styled(InfoText)`
  font-weight: bold;
  text-align: left;
`

export const MoneyTextMask = styled(NumericFormat).attrs (props => ({
  value: props.value.toFixed (2).replace ('.', ','),
  thousandSeparator: '.',
  decimalSeparator: ',',
  displayType: 'text',
  prefix: 'R$ '
}))`
  color: ${props => parseFloat (props.value) >= 0 ? '#44CF6C' : '#FF3939'};
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  padding: 0px;
`

import styled from 'styled-components'

export const Container = styled.div`
  background-color: #FFFFFF;
  width: 100%;
  min-height: 38px;
  margin: 0px 0px 10px;
  box-shadow: 0px 2px 10px -6px #252525;
  text-decoration: none;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 180px repeat(3, 80px) 120px;
  gap: 10px;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding: 7px 12px;

  &:hover {
    background-color: #F7F7F7;
    cursor: pointer;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const InfoText = styled.p`
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin: 0px;
`

export const SubTitle = styled(InfoText)`
  font-weight: bold;
  text-align: left;
`

export const MoneyTextMask = styled.p`
  color: #44CF6C;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  padding: 0px;
`

export const Button = styled.button`
  width: 32px;
  height: 32px;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    background-color: #F0F0F0;
    box-shadow: 0px 2px 10px -6px #252525;
  }
`

export const InfoLink = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  padding: 0px;
  width: 100%;
  background-color: transparent;

  &:hover, &:active {
    border: 0px;
  }

  &:hover p {
    color: #35A7FF
  };
`

import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import AdminNavbar from '../../../../components/AdminNavbar'
import Select from '../../../../components/Inputs/Select'
import AlertDialog from '../../../../components/AlertDialog'
import { Formik } from 'formik'
import productValidator from '../../../../validators/Admin/productValidator'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading.svg'
import '../../../../styles/calendar.css'

import {
  Container,
  Form,
  FormField,
  Title,
  Input,
  Button,
  Row,
  InfoText,
  WarningText,
  ErrorContainer
} from './styles'

function NewProduct ({ history }) {
  const [errorMessage, setErrorMessage] = useState (null)
  const [categories, setCategories] = useState ([])
  const [loading, setLoading] = useState (true)
  const [showOkDialog, setShowOkDialog] = useState (false)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      const { data: categories } = await cutileiApi.get ('/product_categories', requestConfig)

      setCategories (categories.map (category => ({
        label: category.name,
        value: category.id
      })))
    } catch (error) {
      console.log (error.response)
    } finally {
      setLoading (false)
    }
  }

  const toggleOkDialog = () => setShowOkDialog (!showOkDialog)

  return (
    <Container>
      <AdminNavbar/>
      {loading ? <Loading/> : (
        <Formik
          validationSchema={productValidator}
          initialValues={{
            'name': '',
            'brand': '',
            'description': '',
            'category': null
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const { category, ...product } = values

            try {
              await cutileiApi.post ('/products', {
                productCategoryId: category.value,
                ...product
              }, requestConfig)

              setSubmitting (false)
              toggleOkDialog ()
            } catch (error) {
              setSubmitting (false)
              console.log (error)
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Title>Cadastrar produto</Title>
              <Row>
                <FormField>
                  <Input 
                    placeholder='Nome'
                    value={values.name}
                    onChange={handleChange ('name')} 
                    onBlur={handleBlur ('name')}
                  />
                  {touched.name && errors.name && (
                    <WarningText>
                      {errors.name}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Input 
                    placeholder='Marca'
                    value={values.brand}
                    onChange={handleChange ('brand')} 
                    onBlur={handleBlur ('brand')}
                  />
                  {touched.brand && errors.brand && (
                    <WarningText>
                      {errors.brand}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Select
                    name='category'
                    placeholder='Categoria...'
                    value={values.category}
                    options={categories}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.category}
                    touched={touched.category}
                  />
                  {touched.category && errors.category && (
                    <WarningText>
                      {errors.category}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <Input 
                    placeholder='Descrição do produto'
                    value={values.description}
                    onChange={handleChange ('description')} 
                    onBlur={handleBlur ('description')}
                  />
                  {touched.description && errors.description && (
                    <WarningText>
                      {errors.description}
                    </WarningText>
                  )}
                </FormField>
              </Row>

              {errorMessage && (
                <ErrorContainer>
                  <InfoText>{errorMessage}</InfoText>
                </ErrorContainer>
              )}

              <AlertDialog
                visible={showOkDialog}
                title='Sucesso'
                message='Produto cadastrado!'
                confirmText='Ok'
                onConfirm={() => history.replace ('/admin/config')}
                onClose={() => history.replace ('/admin/config')}
              />

              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : 'Criar produto'}
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  )
}

export default withRouter (NewProduct)

import React, { useState, useEffect } from 'react'
import * as FA from 'react-icons/fa'

import {
  Container,
  MaskedInput,
  ArrowContainer,
  ArrowButton
} from './styles'

function NumberInput ({
  id,
  name,
  value: _value,
  minValue = 0,
  maxValue,
  step = 1,
  placeholder = '',
  onChange: handleChange,
  onBlur = () => null,
  style = {},
  disabled = false,
  ...props
}) {
  const [value, setValue] = useState (_value)

  useEffect (() => {
    setValue (_value)
  }, [_value])

  useEffect (() => {
    handleChange (name, value)
  }, [value])

  const addStep = () => {
    if (value === maxValue) return
    setValue (value + step)
  }

  const subtractStep = () => {
    if (value === minValue) return
    setValue (value - step)
  }

  const handleBlur = () => {
    if (minValue && value < minValue) {
      setValue (minValue)
      handleChange (name, minValue)
    }
    else if (maxValue && value > maxValue) {
      setValue (maxValue)
      handleChange (name, maxValue)
    }
    onBlur (name)
  }

  return ( 
    <Container style={style}>
      <MaskedInput
        id={id}
        style={{marginRight: 5}}
        placeholder={placeholder}
        options={{
          numericOnly: true,
          blocks: [5]
        }}
        value={`${value}`}
        onChange={event => setValue (event.target.value === '' ? 0 : parseInt (event.target.value))}
        onBlur={handleBlur}
        disabled={disabled}
        {...props}
      />
      {!disabled && (
        <ArrowContainer>
          <ArrowButton onClick={addStep} type='button'>
            <FA.FaChevronUp/>
          </ArrowButton>
          <ArrowButton onClick={subtractStep} type='button'>
            <FA.FaChevronDown/>
          </ArrowButton>
        </ArrowContainer>
      )}
    </Container>
  )
}

export default NumberInput

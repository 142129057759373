import styled from 'styled-components'

export const Container = styled.div`
  background-color: #FFFFFF;
  min-width: 900px;
  width: 960px;
  min-height: 38px;
  margin: 0px 0px 10px;
  box-shadow: 0px 2px 10px -6px #252525;
  text-decoration: none;
  border-radius: 15px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding: 3px 12px 4px;

  &:hover {
    background-color: #F7F7F7;
  }
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

export const Label = styled.label`
  text-align: center;
  color: #000000;
  margin: 0px;

  &:hover {
    cursor: pointer;
  }
`

export const InfoText = styled.p`
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin: 0px;
`

export const SubTitle = styled(InfoText)`
  font-weight: bold;
  text-align: left;
`

export const Button = styled.button.attrs ({
  type: 'button'
})`
  width: 32px;
  height: 32px;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    background-color: #F0F0F0;
    box-shadow: 0px 2px 10px -6px #252525;
  }
`

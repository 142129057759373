import React from 'react'
import BillTip from './BillTip'

import {
  Container,
  SubTitle,
  LabelRow
} from './styles'

function BillTipList ({
  bill,
  billTips,
  professionals,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  return billTips.length > 0 && (
    <Container>
      <LabelRow>
        <SubTitle>Profissional</SubTitle>
        <SubTitle>Descrição</SubTitle>
        <SubTitle>Valor</SubTitle>
        <SubTitle>Comissão (%)</SubTitle>
        <SubTitle>Ações</SubTitle>
      </LabelRow>
      {billTips.map ((tip, index) =>
        <BillTip
          key={index}
          bill={bill}
          data={tip}
          professionals={professionals}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </Container>
  )
}

export default BillTipList

import React, { useState, useEffect } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import Comission from '../../../../services/Comission'
import DeleteBillDialog from '../../../DeleteBillDialog'
import * as FA from 'react-icons/fa'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading2.svg'
import { DateTime } from 'luxon'

import {
  Container,
  Row,
  InfoText,
  MoneyTextMask,
  Button
} from './styles'

function BusinessBill ({
  data: bill,
  showComissions = false,
  type,
  category = null,
  onEdit,
  onDelete: handleDelete
}) {
  const [deleting, setDeleting] = useState (false)
  const [showDeleteDialog, setShowDeleteDialog] = useState (false)
  const token = AuthService.getToken ()
  const user = AuthService.getUser ()
  
  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const handleEdit = () => onEdit (bill)
  const toggleDeleteDialog = () => setShowDeleteDialog (!showDeleteDialog)

  const deleteBill = async deleteReason => {
    setDeleting (true)
    try {
      await cutileiApi.delete (
        `/business_bills/${bill.id}?delete_reason=${deleteReason}`, requestConfig
      )
      setDeleting (false)
      handleDelete (bill)
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  return (
    <Container showComissions={showComissions}>
      <InfoText>
        Nº{bill.number} ({DateTime.fromISO (bill.date).toFormat ('dd/MM/yyyy')})
      </InfoText>
      <InfoText>
        {bill.customer?.name}
      </InfoText>
      <InfoText>
        {`${bill?.cash_register.opened_by?.name || user.name} ` }
        ({DateTime.fromISO (bill.cash_register.created_at).toFormat ('dd/MM/yyyy - HH:mm:ss')})
      </InfoText>
      <InfoText>
        {DateTime.fromISO (bill.created_at).toFormat ('dd/MM/yyyy - HH:mm:ss')}
      </InfoText>
      <MoneyTextMask value={bill.value}/>
      {showComissions && type && (
        <MoneyTextMask value={Comission.getProfessionalEarnings ([bill], type, category)}/>
      )}
      <Row>
        <Button onClick={handleEdit}>
          {bill.open ? (
            <FA.FaEdit color='#35A7FF' size={18}/>
          ) : (
            <FA.FaEye color='#35A7FF' size={18}/>
          )}
        </Button>
        <Button onClick={toggleDeleteDialog}>
          {deleting ? (
            <ButtonLoading style={{margin: 0}}/>
          ) : (
            <FA.FaTrash color='#FF3939' size={16}/>
          )}
        </Button>
      </Row>
      <DeleteBillDialog
        visible={showDeleteDialog}
        title='Atenção!'
        message='Deseja realmente excluir esta comanda?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteBill}
        onClose={toggleDeleteDialog}
      />
    </Container>
  )
}

export default BusinessBill

import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import Select from '../../../components/Inputs/Select'
import { Formik } from 'formik'
import bankAccountValidator from '../../../validators/bankAccountValidator'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Form,
  FormField,
  Title,
  Input,
  Button,
  CloseButton,
  Row,
  InfoText,
  WarningText,
  ErrorContainer
} from './styles'

function EditBankAccountModal ({
  visible,
  data: bankAccount,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [loading, setLoading] = useState (true)
  const [banks, setBanks] = useState (null)
  const [errorMessage, setErrorMessage] = useState (null)
  const business = AuthService.getUser ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    if (visible) getBanks ()
  }, [visible])

  const getBanks = async () => {
    try {
      setLoading (true)
      const { data: banks } = await cutileiApi.get ('/banks', requestConfig)

      setBanks (banks.map (bank => ({
        label: `(${bank.code}) ${bank.name}`,
        value: bank
      })))
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      {loading ? <Loading/> : (
        <Formik
          validationSchema={bankAccountValidator}
          initialValues={{
            'bank': {
              label: `(${bankAccount.bank.code}) ${bankAccount.bank.name}`,
              value: bankAccount.bank
            },
            'description': bankAccount.description
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const { bank, description } = values

            try {
              const { data: editedBankAccount } = await cutileiApi.put (
                `/businesses/${business.id}/bank_accounts/${bankAccount.id}`,
                {bankId: bank.value.id, description},
                requestConfig
              )

              setSubmitting (false)
              handleConfirm (editedBankAccount)
              handleClose ()
            } catch (error) {
              if (error.response.data) setErrorMessage (error.response.data.message)
              setSubmitting (false)
              console.log (error)
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit}>
              <Title>Editar conta bancária</Title>
              <CloseButton onClick={handleClose}>
                <FA.FaTimes color='#FF3939' size={18}/>
              </CloseButton>
              <Row>
                <FormField>
                <Select
                    name='bank'
                    placeholder='Banco...'
                    value={values.bank}
                    options={banks}
                    isClearable={true}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.bank}
                    touched={touched.bank}
                  />
                  {touched.bank && errors.bank && (
                    <WarningText>
                      {errors.bank}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Input 
                    placeholder='Descrição'
                    value={values.description}
                    onChange={handleChange ('description')} 
                    onBlur={handleBlur ('description')}
                  />
                  {touched.description && errors.description && (
                    <WarningText>
                      {errors.description}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              {errorMessage && (
                <ErrorContainer>
                  <InfoText>{errorMessage}</InfoText>
                </ErrorContainer>
              )}
              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : 'Salvar alterações'}
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  )
}

export default EditBankAccountModal

import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import AlertDialog from '../../../components/AlertDialog'
import { Formik } from 'formik'
import passwordValidator from '../../../validators/passwordValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'

import {
  Container,
  Form,
  Title,
  Row,
  FormField,
  Input,
  WarningText,
  Button
} from '../styles'

function ChangePassword ({ history }) {
  const [showOkDialog, setShowOkDialog] = useState (false)
  const user = AuthService.getUser ()
  const token = AuthService.getToken ()
  const isBusiness = AuthService.isBusiness ()

  const toggleOkDialog = () => setShowOkDialog (!showOkDialog)

  return (
    <Container>
      <Formik
        validationSchema={passwordValidator}
        initialValues={{
          'password': '',
          'passwordConfirmation': ''
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const url = isBusiness ? `/businesses/${user.id}` : `/professionals/${user.id}`
          try {
            await cutileiApi.put (url, {password: values.password}, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })

            setSubmitting (false)
            toggleOkDialog ()
          } catch (error) {
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit} style={{width: 480}}>
            <Title>Alterar senha</Title>
            <Row>
              <FormField style={{margin: 0}}>
                <Input
                  placeholder='Senha'
                  type='password'
                  value={values.password}
                  onChange={handleChange ('password')}
                  onBlur={handleBlur ('password')}
                />
                {touched.password && errors.password && (
                  <WarningText>{ errors.password }</WarningText>
                )}
              </FormField>
            </Row>
            <Row>
              <FormField style={{margin: 0}}>
                <Input
                  placeholder='Confirmar senha'
                  type='password'
                  value={values.passwordConfirmation}
                  onChange={handleChange ('passwordConfirmation')}
                  onBlur={handleBlur ('passwordConfirmation')}
                />
                {touched.passwordConfirmation && errors.passwordConfirmation && (
                  <WarningText>{ errors.passwordConfirmation }</WarningText>
                )}
              </FormField>
            </Row>

            <AlertDialog
              visible={showOkDialog}
              title='Sucesso'
              message='Senha alterada!'
              confirmText='Ok'
              onConfirm={() => history.replace ('/business')}
              onClose={() => history.replace ('/business')}
            />

            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Alterar senha'}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
 
export default withRouter (ChangePassword)

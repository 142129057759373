import * as Yup from 'yup'

const createReservationValidator = Yup.object ({
  professionals: Yup.array ().of (
    Yup.object ()
  ).min (1, 'Selecione um profissional'),

  description: Yup
    .string ()
    .required ('Insira uma descrição')
    .max (128, 'A descrição deve ter até 128 caracteres'),

  startDate: Yup
    .string ()
    .required ('Selecione uma data de início'),

  endDate: Yup
    .string ()
    .required ('Selecione uma data final'),
  
  period: Yup
    .object ()
    .nullable ()
    .required ('Selecione um período'),

  startTime: Yup
    .object ()
    .nullable ()
    .required ('Selecione o horário inicial'),

  endTime: Yup
    .object ()
    .nullable ()
    .required ('Selecione o horário final'),

  repeat: Yup.boolean (),

  weekDays: Yup.array ().of (Yup.object ().shape ({
    selected: Yup.boolean ()
  })).compact (v => v.selected === false).min (1, 'Selecione pelo menos um dia da semana')
})

export default createReservationValidator

import React, { useState } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import NumberInput from '../../../components/Inputs/NumberInput'
import MoneyInput from '../../../components/Inputs/MoneyInput'
import businessProductValidator from '../../../validators/Admin/productValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import { Formik } from 'formik'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Form,
  FormField,
  Input,
  Button,
  CloseButton,
  Row,
  Title,
  Label,
  WarningText,
  ErrorContainer
} from './styles'

function EditProductModal ({
  visible,
  data: product,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const token = AuthService.getToken ()
  const user = AuthService.getUser ()
  const isBusiness = AuthService.isBusiness ()
  const businessId = isBusiness ? user.id : user.business_id

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={businessProductValidator}
        initialValues={{
          'id': product?.id,
          'nickname': product?.nickname,
          'stock': product?.stock,
          'price': product?.price,
          'comission_percentage': product?.comission_percentage * 100,
          'net_value': product?.comission_percentage * product?.price || 0
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setErrorMessage (null)
          setSubmitting (true)

          try {
            const data = { 
              ...values, comission_percentage: values.comission_percentage / 100
            }

            const url = `/businesses/${businessId}/products/${values.id}`

            await cutileiApi.put (url, data, requestConfig)

            setSubmitting (false)
            handleConfirm ({...product, ...data})
            handleClose ()
          } catch (error) {
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleChange,
          handleBlur, 
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            <Title>Editar produto</Title>
            <CloseButton onClick={handleClose}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </CloseButton>
            <Row>
              <FormField stretch={2}>
                <Label htmlFor='nickname' style={{marginBottom: 4}}>
                  Apelido
                </Label>
                <Input
                  id='nickname'
                  placeholder='Apelido'
                  value={values.nickname}
                  onChange={handleChange ('nickname')} 
                  onBlur={handleBlur ('nickname')}
                />
              </FormField>
              <FormField>
                <Label htmlFor='stock'>
                  Qtd. estoque
                </Label>
                <NumberInput
                  id='stock'
                  name='stock'
                  value={values.stock}
                  minValue={1}
                  onChange={setFieldValue}
                  style={{width: '100%'}}
                />
              </FormField>
            </Row>
            <Row style={{alignItems: 'flex-start'}}>
              <FormField>
                <Label style={{marginBottom: 4}} htmlFor='price'>
                  Preço (R$)
                </Label>
                <MoneyInput
                  id='price'
                  name='price'
                  placeholder='Preço'
                  value={values.price}
                  onValueChange={(name, value) => {
                    setFieldValue (name, value)
                    setFieldValue (
                      'net_value',
                      Math.round ((values.comission_percentage / 100) * value * 100) / 100
                    )
                  }}
                  onBlur={handleBlur ('price')}
                />
                {touched.price && errors.price && (
                  <WarningText>
                    {errors.price}
                  </WarningText>
                )}
              </FormField>
              <FormField>
                <Label htmlFor='comission_percentage'>
                  Comissão (%)
                </Label>
                <NumberInput
                  id='comission_percentage'
                  name='comission_percentage'
                  value={values.comission_percentage}
                  minValue={0}
                  maxValue={100}
                  onChange={(name, value) => {
                    setFieldValue (name, value)
                    setFieldValue (
                      'net_value',
                      Math.round ((1 - value / 100) * values.price * 100) / 100
                    )
                  }}
                  style={{width: '100%'}}
                />
              </FormField>
              <FormField style={{marginLeft: 5}}>
                <Label htmlFor='net_value'>
                  Valor líquido
                </Label>
                <MoneyInput
                  id='net_value'
                  name='net_value'
                  placeholder='Valor líquido'
                  value={values.net_value ?? 0}
                  onValueChange={(name, value) => {
                    setFieldValue (
                      'comission_percentage',
                      Math.round ((1 - value / values.price) * 100)
                    )
                    setFieldValue (name, value)
                  }}
                  onBlur={handleBlur ('net_value')}
                  style={{marginTop: 3, color: '#44CF6C', fontSize: 16, fontWeight: 'bold'}}
                />
              </FormField>
            </Row>

            {errorMessage && (
              <ErrorContainer>
                <p>{errorMessage}</p>
              </ErrorContainer>
            )}

            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Salvar'}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default EditProductModal

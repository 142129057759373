import styled from 'styled-components'
import { NumericFormat } from 'react-number-format'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
`

export const Row = styled(Container)`
  width: 100%;
  padding: 0px;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: flex-start;
`

export const CalendarContainer = styled(Container)`
  padding: 0px;
  margin: 0px 30px 0px 0px;
`

export const FormField = styled(Container)`
  flex: 1;
  justify-content: flex-end;
  padding: 0px;

  &:first-child {
    margin-right: 5px;
  }

  &:last-child {
    margin-left: 5px;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  width: 540px;
  max-width: 50vw;
  box-shadow: 0px 2px 10px -6px #252525;
  background-color: #F7F7F7;
  padding: 10px;
  border-radius: 15px;
`

export const Button = styled.button`
  width: 200px;
  align-self: center;
  background-color: #252525;
  color: #FFFFFF;
  padding: 8px;
  border: 0px;
  border-radius: 15px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #121212;
    color: #FFFFFF;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  margin: 0px 5px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const NumberInput = styled(NumericFormat).attrs({
  customInput: Input,
  thousandSeparator: '.',
  decimalSeparator: ',',
  displayType: 'input',
  allowNegative: false
})``

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  align-self: center;
`;

export const InfoText = styled.p`
  text-align: center;
  color: #FFFFFF;
  margin: 0px;
`

export const WarningText = styled(InfoText)`
  color: #FF3939;
`

export const Label = styled.label`
  text-align: center;
  color: #000000;
  margin: 0px;

  &:hover {
    cursor: pointer;
  }
`

export const ErrorContainer = styled.div`
  padding: 9px;
  margin-top: 15px;
  width: 100%;
  border-radius: 15px;
  align-items: center;
  background-color: #FF3939;
`

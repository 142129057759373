import React, { useState } from 'react'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import Record from './Record'
import { Formik } from 'formik'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'

import {
  Container,
  Row,
  InfoText,
  Input,
  Button,
  ButtonText,
  WarningText,
  ErrorContainer
} from './styles'

function RecordList ({
  records,
  customerID,
  onRecordAdded: handleRecordAdded,
  onDelete: handleDelete
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const token = AuthService.getToken ()
  const user = AuthService.getUser ()
  const isBusiness = AuthService.isBusiness ()
  const businessId = isBusiness ? user.id : user.business_id

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  return (
    <Container>
      {records.length > 0 ? records.map (record =>
        <Record
          key={record.id}
          data={record}
          onDelete={handleDelete}
        />
      ) : (
        <InfoText>
          Este cliente ainda não possui anotações no prontuário
        </InfoText>
      )}

      <Formik
        // validationSchema={paymentValidator}
        initialValues={{
          'description': '',
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const { data: record } = await cutileiApi.post ('/records', {
              customerId: customerID, businessId, ...values
            }, requestConfig)

            setSubmitting (false)
            resetForm ()
            handleRecordAdded (record)
          } catch (error) {
            if (error.response.data) setErrorMessage (error.response.data.message)
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Row>
            <Input
              placeholder='Novo registro'
              value={values.description}
              onChange={handleChange ('description')}
              onBlur={handleBlur ('description')}
            />
            {touched.description && errors.description && (
              <WarningText>
                {errors.description}
              </WarningText>
            )}

            <Button type='submit' onClick={handleSubmit} disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Registrar'}
            </Button>
          </Row>
        )}
      </Formik>

      {errorMessage && (
        <ErrorContainer>
          <ButtonText>{errorMessage}</ButtonText>
        </ErrorContainer>
      )}
    </Container>
  )
}

export default RecordList

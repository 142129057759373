import React, { useState, useEffect } from 'react'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import BusinessBillList from '../../../components/Lists/BusinessBillList'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  ListContainer,
  Row,
  Input
} from '../styles'

function ClosedBills () {
  const [loading, setLoading] = useState (true)
  const [date, setDate] = useState (DateTime.now ().toISODate ())
  const [closedBills, setClosedBills] = useState ([])
  const token = AuthService.getToken ()
  const business = AuthService.getUser ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [date])

  const getData = async () => {
    try {
      setLoading (true)

      const { data: closedBills } = await cutileiApi.get (
        `/business_bills?business_id=${business.id}&deleted=false&date=${date}&open=false`,
        requestConfig
      )

      setClosedBills (closedBills)
    } catch (error) {
      console.log (error.response.data)
    } finally {
      setLoading (false)
    }
  }
  
  return (
    <ListContainer>
      <Row style={{marginBottom: 15}}>
        <Input
          type='date'
          placeholder='Data de início'
          value={date}
          onChange={e => setDate (e.target.value)}
        />
      </Row>
      {loading ? <Loading/> : (
        <BusinessBillList
          bills={closedBills}
          onEdit={bill => setClosedBills (closedBills.map (b => b.id === bill.id ? {...bill} : b))}
          onDelete={bill => setClosedBills (closedBills.filter (b => b.id !== bill.id))}
        />
      )}
    </ListContainer>
  )
}

export default ClosedBills

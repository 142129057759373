import React, { useState } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import MaskedInputService from '../../../services/maskedInput'
import customerValidator from '../../../validators/customerValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import { Formik } from 'formik'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Form,
  FormField,
  Input,
  MaskedInput,
  Button,
  CloseButton,
  Row,
  Title,
  Label,
  WarningText,
  ErrorContainer
} from './styles'

function EditCustomerModal ({
  visible,
  data: customer,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={customerValidator}
        initialValues={{
          'name': customer?.name ?? '',
          'email': customer?.email,
          'phone': customer?.phone
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setErrorMessage (null)
          setSubmitting (true)

          try {
            const { data } = await cutileiApi.put (
              `/customers/${customer.id}`, values, requestConfig
            )

            setSubmitting (false)
            handleConfirm ({...data, name: values.name})
            handleClose ()
          } catch (error) {
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            <Title>Editar cliente</Title>
            <CloseButton onClick={handleClose}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </CloseButton>
            <Row>
              <FormField>
                <Label htmlFor='name' style={{marginBottom: 4}}>
                  Nome
                </Label>
                <Input
                  id='name'
                  placeholder='Nome'
                  value={values.name}
                  onChange={handleChange ('name')} 
                  onBlur={handleBlur ('name')}
                />
              </FormField>
            </Row>
            <Row>
              <FormField>
                <Label htmlFor='email' style={{marginBottom: 4}}>
                  E-mail
                </Label>
                <Input
                  id='email'
                  type='email'
                  placeholder='E-mail'
                  value={values.email}
                  onChange={handleChange ('email')} 
                  onBlur={handleBlur ('email')}
                />
                {touched.email && errors.email && (
                  <WarningText>
                    {errors.email}
                  </WarningText>
                )}
              </FormField>
            </Row>
            <Row>
              <FormField>
                <Label htmlFor='phone' style={{marginBottom: 4}}>
                  Celular
                </Label>
                <MaskedInput
                  name='phone'
                  placeholder='Celular'
                  options={{
                    blocks: [0, 2, 5, 4],
                    delimiters: ['(', ') ', '-'],
                    numericOnly: true,
                  }}
                  value={values.phone}
                  onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                  onBlur={handleBlur ('phone')}
                />
                {touched.phone && errors.phone && (
                  <WarningText>
                    {errors.phone}
                  </WarningText>
                )}
              </FormField>
            </Row>

            {errorMessage && (
              <ErrorContainer>
                <p>{errorMessage}</p>
              </ErrorContainer>
            )}

            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Salvar'}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default EditCustomerModal

import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import BusinessBillList from '../../../components/Lists/BusinessBillList'
import AddBillModal from '../AddBillModal'
// import CreateScheduleModal from '../../Modals/CreateScheduleModal'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  ListContainer,
  Row,
  Button
} from '../styles'

function OpenBills ({ history }) {
  const [loading, setLoading] = useState (true)
  const [openBills, setOpenBills] = useState ([])
  const [billData, setBillData] = useState (null)
  const [showAddBillModal, setShowAddBillModal] = useState (false)
  const [showCreateScheduleModal, setShowCreateScheduleModal] = useState (false)
  const token = AuthService.getToken ()
  const business = AuthService.getUser ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      setLoading (true)

      const { data: openBills } = await cutileiApi.get (
        `/business_bills?business_id=${business.id}&deleted=false&open=true`,
        requestConfig
      )

      setOpenBills (openBills)
    } catch (error) {
      console.log (error.response.data)
    } finally {
      setLoading (false)
    }
  }

  const toggleAddBillModal = () => setShowAddBillModal (!showAddBillModal) 
  const toggleCreateScheduleModal = () => setShowCreateScheduleModal (!showCreateScheduleModal) 
  
  return (
    <ListContainer>
      <Row style={{marginBottom: 15}}>
        <Button style={{margin: 0}} onClick={toggleAddBillModal}>
          <FA.FaPlus size={12} style={{marginTop: 2, marginRight: 7}}/>
          Abrir comanda
        </Button>
      </Row>
      {loading ? <Loading/> : (
        <BusinessBillList
          bills={openBills}
          editBillData={billData}
          onEdit={bill => setOpenBills (openBills.map (b => b.id === bill.id ? {...bill} : b))}
          onDelete={bill => setOpenBills (openBills.filter (b => b.id !== bill.id))}
        />
      )}
      <AddBillModal
        visible={showAddBillModal}
        onConfirm={bill => {
          setBillData (bill)
          setOpenBills ([...openBills, bill])
        }}
        onClose={toggleAddBillModal}
      />
      {/* <CreateScheduleModal
        visible={showAddScheduleModal}
        onConfirm={() => history.replace ('/business')}
        onClose={toggleAddScheduleModal}
      /> */}
    </ListContainer>
  )
}

export default withRouter (OpenBills)

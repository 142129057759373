import styled from 'styled-components'

export const Container = styled.nav`
  background-color: #F0F0F0;
  height: 100vh;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  padding-top: 20px;
  z-index: 5;
  top: 0px;
  left: 0px;
`

export const Label = styled.label`
  text-align: center;
  color: #000000;
  margin: 0px;

  &:hover {
    cursor: pointer;
  }
`

export const Image = styled.img`
  width: 32px;
  height: 32px;
  margin-bottom: 5px;
`

export const NavItem = styled.li`
  width: 100%;
  list-style: none;

  a {
    color: #252525;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 3px 5px;
    padding: 8px 15px;

    svg {
      width: 26px;
      height: 26px;
    }

    &:hover, &:focus {
      box-shadow: 0px 2px 10px -6px #252525;
    }

    &.active {
      background-color: #FFFFFF;
      box-shadow: 0px 2px 10px -6px #252525;
    }

    &.active svg {
      color: #FFC100 !important;
    }
  }
`

import React, { useState, useEffect } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import RecordList from '../../../../components/Lists/RecordList'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'

import {
  Container,
} from '../styles'

function CustomerRecord ({ customer }) {
  const [loading, setLoading] = useState (true)
  const [records, setRecords] = useState ([])
  const token = AuthService.getToken ()
  const user = AuthService.getUser ()
  const isBusiness = AuthService.isBusiness ()
  const businessId = isBusiness ? user.id : user.business_id

  useEffect (() => {
    getRecords ()
  }, [])

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const getRecords = async () => {
    try {
      const { data: records } = await cutileiApi.get (
        `/records?customer_id=${customer.id}&business_id=${businessId}`,
        requestConfig
      )
      setRecords (records)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Container style={{paddingInline: 10}}>
      {loading ? <Loading style={{marginBottom: 20}}/> : (
        <RecordList
          records={records}
          customerID={customer.id}
          onRecordAdded={record => setRecords ([record, ...records])}
          onDelete={record => setRecords (records.filter (r => r.id !== record.id))}
        />
      )}
    </Container>
  )
}

export default CustomerRecord


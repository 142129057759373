import { DateTime } from "luxon"

class TimeWindow {
  static getTimeWindows = (start, end, interval = 30) => {
    let timeWindows = []
    let timeWindow = DateTime.fromFormat (start, 'H:mm').isValid
      ? DateTime.fromFormat (start, 'H:mm')
      : DateTime.fromFormat (start, 'HH:mm:ss')

    const endTime = DateTime.fromFormat (end, 'H:mm').isValid
      ? DateTime.fromFormat (end, 'H:mm')
      : DateTime.fromFormat (end, 'HH:mm:ss')

    while (timeWindow <= endTime) {
      timeWindows.push (timeWindow)
      timeWindow = timeWindow.plus ({ minutes: interval })
    }
    
    return timeWindows.map (timeWindow => ({
      label: timeWindow.toFormat ('H:mm'),
      value: timeWindow.toFormat ('H:mm')
    }))
  }

  static getYears = (start, end) => {
    let years = []
    let year = DateTime.fromFormat (start, 'yyyy')
    
    while (year <= DateTime.fromFormat (end, 'yyyy')) {
      years.push (year)
      year = year.plus ({ years: 1 })
    }
    
    return years.map (year => ({
      label: year.toFormat ('yyyy'),
      value: year.toFormat ('yyyy')
    }))
  }

  static getMonths = () => {
    let months = []
    let month = DateTime.fromFormat ('01', 'MM')
    
    while (month <= DateTime.fromFormat ('12', 'MM')) {
      months.push (month)
      month = month.plus ({ months: 1 })
    }
    
    return months.map (month => ({
      label: month.setLocale ('pt-BR').toFormat ('MMMM'),
      value: month.month
    }))
  }
}

export default TimeWindow

import React from 'react'
import BillService from './BillService'

import {
  Container,
  SubTitle,
  LabelRow
} from './styles'

function BillServiceList ({
  bill,
  billServices,
  customerServices = [],
  showFreeItemsValue = false,
  enableEditting = true,
  professionals,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  return billServices.length > 0 && (
    <Container>
      <LabelRow enableEditting={enableEditting}>
        <SubTitle>Serviço</SubTitle>
        <SubTitle>Profissional</SubTitle>
        <SubTitle>Qtd</SubTitle>
        <SubTitle>Preço</SubTitle>
        <SubTitle>Comissão (%)</SubTitle>
        {enableEditting && <SubTitle>Ações</SubTitle>}
      </LabelRow>
      {billServices.map ((service, index) =>
        <BillService
          key={index}
          bill={bill}
          data={service}
          professionals={professionals}
          isFree={customerServices.some (s => s.id === service.id) || service.bundle_id}
          showFreeItemsValue={showFreeItemsValue}
          enableEditting={enableEditting}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </Container>
  )
}

export default BillServiceList

import * as Yup from 'yup'
import { isValid as isValidCpf } from "@fnando/cpf"
// import cutileiApi from '../services/cutileiApi'

// const isNonExistingUser = async (key, value) => {
//   try {
//     await cutileiApi.post ('/professionals/verify_existing', {
//       [key]: value
//     })
//     return true
//   } catch (error) {
//     return false
//   }
// }

const phoneRegex = /\(|\)| |-/g
const rgRegex = (/^(\d{1,2})(\d{3})(\d{3})([\dX])$/)
const postalCodeRegex = (/^(\d{5})(\d{3})$/)

const createProfessionalValidation = Yup.object ({
  number: Yup.number ().nullable (),

  name: Yup.string ()
    .required ('Digite um nome')
    .min (4, 'O nome deve conter no minimo 4 caracteres')
    .max (128, 'O nome deve conter até 128 caracteres'),

  nickname: Yup.string ()
    .min (4, 'O apelido deve conter no minimo 4 caracteres')
    .max (64, 'O apelido deve conter até 64 caracteres'),

  cpf: Yup.string ()
    .required ('Digite o CPF')
    .test ('validCpf', 'Digite um CPF válido', function (value) {
      return isValidCpf (value)
    }),
    // .test ('newCpf', 'Este CPF já foi cadastrado', async (value) => {
    //   if (value) {
    //     return isNonExistingUser ('cpf', strip (value))
    //   }
    // }),

  rg: Yup.string ()
    .test ('validRG', 'Digite um RG válido', function (value) {
      if (!value) return true
      return rgRegex.test (value)
    }),
    // .test ('newRg', 'Este RG já foi cadastrado', async (value) => {
    //   if (value) {
    //     return isNonExistingUser ('rg', value)
    //   }
    //   return true
    // }),

  email: Yup.string ()
    .required ('Digite um email')
    .email ('Insira um email válido!')
    .max (128, 'O e-mail deve conter até 128 caracteres'),
    // .test ('newEmail', 'Este endereço de e-mail já foi cadastrado', async (value) => {
    //   if (value) {
    //     return isNonExistingUser ('email', value)
    //   }
    // }),

  birth: Yup.string ()
    .required ('Digite a data de nascimento')
    .min (8, 'Digite uma data válida (dd/mm/aaaa)'),
    
  phone: Yup.string ()
    .required ('Digite o número de celular')
    .test ('validPhone', 'Insira um número válido', function (value) {
      if (!value) return true
      return value.replace (phoneRegex, '').length === 11
    }),
    // .test ('newPhone', 'Este número de celular já foi cadastrado', async (value) => {
    //   if (value) {
    //     return isNonExistingUser ('phone', value.replace (phoneRegex, ''))
    //   }
    // }),

  gender: Yup.object ()
    .nullable ()
    .required ('Selecione o gênero'),

  access_level: Yup.object ()
    .nullable ()
    .required ('Selecione o nível de acesso'),

  password: Yup.string ()
    .required ('Digite uma senha')
    .min (8, 'A senha deve conter no mínimo 8 caracteres'),

  passwordConfirmation: Yup.string ()
    .required ('Confirme a senha')
    .oneOf ([Yup.ref ('password'), null], 'As duas senhas devem ser iguais'),

  postal_code: Yup
    .string ()
    .required ('Digite o CEP')
    .test ('validPostalCode', 'Digite um CEP válido', function (value) {
      return postalCodeRegex.test (value)
    }),

  number: Yup
    .string ()
    .required ('Digite o número da residência')
    .max (5, 'O numero deve ter no máximo 5 digitos'),

  complement: Yup.string (),

  working_days: Yup.array ().of (Yup.object ().shape ({
    selected: Yup.boolean (),
    work_start: Yup.object ().nullable ().when ('selected', {
      is: true,
      then: schema => schema.required ('Selecione o horário de início de expediente')
    }),
    work_end: Yup.object ().nullable ().when ('selected', {
      is: true,
      then: schema => schema.required ('Selecione o horário de final de expediente')
    }),
    accepts_cutilei: Yup.boolean (),
    cutilei_start: Yup.object ().nullable ().when ('accepts_cutilei', {
      is: true,
      then: schema => schema.required ('Selecione o horário de início do atendimento Cutilei')
    }),
    cutilei_end: Yup.object ().nullable ().when ('accepts_cutilei', {
      is: true,
      then: schema => schema.required ('Selecione o horário de término do atendimento Cutilei')
    })
  }))
})

export default createProfessionalValidation

class Comission {
  static getTotalEarnings (earnings, type, category, professional = null) {
    const cutileiEarnings = earnings.filter (earning => earning.type === 'cutilei')
    const billEarnings = earnings.filter (earning => earning.type === 'bill')
    let cutileiEarningsTotal = 0
    let billEarningsTotal = 0
    const freeServices = service => !service.bundle_id
    const sumItemTotal = (total, item) => total + item.custom_price

    if (['cutilei', 'all_earnings'].includes (type.value))
      cutileiEarningsTotal += cutileiEarnings
        .filter (schedule => this.filterProfessionalEarnings (schedule, professional))
        .reduce ((total, schedule) => (
          total + schedule.price
        ), 0)

    if (['bill', 'all_earnings'].includes (type.value)) {
      const scheduleEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.schedules.filter (freeServices)
          .filter (schedule => this.filterProfessionalEarnings (schedule, professional))
          .filter (schedule => category ? schedule.service.category.id === category?.value : true)
          .reduce ((total, schedule) => total + schedule.price, 0)
      ), 0)

      const serviceEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.services.filter (freeServices)
          .filter (service => this.filterProfessionalEarnings (service, professional))
          .filter (service => category ? service.category.id === category?.value : true)
          .reduce (sumItemTotal, 0)
      ), 0)

      const productEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.products
          .filter (product => this.filterProfessionalEarnings (product, professional))
          .reduce (sumItemTotal, 0)
      ), 0)

      const bundleEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.bundles
          .filter (bundle => this.filterProfessionalEarnings (bundle, professional))
          .reduce (sumItemTotal, 0)
      ), 0)

      billEarningsTotal = (
        scheduleEarningsTotal + serviceEarningsTotal
        + productEarningsTotal + bundleEarningsTotal
      )
    }

    return cutileiEarningsTotal + billEarningsTotal
  }

  static getBusinessEarnings (earnings, type, category, professional = null) {
    const cutileiEarnings = earnings.filter (earning => earning.type === 'cutilei')
    const billEarnings = earnings.filter (earning => earning.type === 'bill')
    let cutileiEarningsTotal = 0
    let billEarningsTotal = 0

    const freeServices = service => !service.bundle_id
    const sumBusinessItemsTotal = (total, item) => (
      total + (item.custom_price ?? item.value) * (
        1 - (item.comission_percentage ?? (
          item.professional_id ? item.custom_comission_percentage : 0
        ))
      )
    )

    if (['cutilei', 'all_earnings'].includes (type.value))
      cutileiEarningsTotal += cutileiEarnings
        .filter (schedule => this.filterProfessionalEarnings (schedule, professional))
        .reduce ((total, schedule) => (
          total + schedule.price * (1 - schedule.comission_percentage)
        ), 0)

    if (['bill', 'all_earnings'].includes (type.value)) {
      const scheduleEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.schedules.filter (freeServices)
          .filter (schedule => this.filterProfessionalEarnings (schedule, professional))
          .filter (schedule => category ? schedule.service.category.id === category?.value : true)
          .reduce ((total, schedule) => (
            total + schedule.price * (1 - schedule.comission_percentage)
          ), 0)
        ), 0)
        
      const serviceEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.services.filter (freeServices)
          .filter (service => this.filterProfessionalEarnings (service, professional))
          .filter (service => category ? service.category.id === category?.value : true)
          .reduce (sumBusinessItemsTotal, 0)
      ), 0)

      const productEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.products
          .filter (product => this.filterProfessionalEarnings (product, professional))
          .reduce (sumBusinessItemsTotal, 0)
      ), 0)

      const bundleEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.bundles
          .filter (bundle => this.filterProfessionalEarnings (bundle, professional))
          .reduce (sumBusinessItemsTotal, 0)
      ), 0)

      const tipEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.tips
          .filter (tip => this.filterProfessionalEarnings (tip, professional))
          .reduce (sumBusinessItemsTotal, 0)
      ), 0)

      billEarningsTotal = (
        scheduleEarningsTotal + serviceEarningsTotal + productEarningsTotal
        + bundleEarningsTotal + tipEarningsTotal
      )
    }

    return cutileiEarningsTotal + billEarningsTotal
  }

  static getProfessionalEarnings (earnings, type, category, professional = null) {
    const cutileiEarnings = earnings.filter (earning => earning.type === 'cutilei')
    const billEarnings = earnings.filter (earning => earning.type === 'bill')
    let cutileiEarningsTotal = 0
    let billEarningsTotal = 0

    const sumBusinessItemsTotal = (total, item) => (
      total + (item.custom_price ?? item.value) * item.comission_percentage ?? (
        item.professional_id ? item.custom_comission_percentage : 0
      )
    )

    if (['cutilei', 'all_earnings'].includes (type.value))
      cutileiEarningsTotal += cutileiEarnings
        .filter (schedule => this.filterProfessionalEarnings (schedule, professional))
        .reduce ((total, schedule) => (
          total + schedule.price * schedule.comission_percentage
        ), 0)

    if (['bill', 'all_earnings'].includes (type.value)) {
      const scheduleEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.schedules
          .filter (schedule => this.filterProfessionalEarnings (schedule, professional))
          .filter (schedule => category ? schedule.service.category.id === category?.value : true)
          .reduce ((total, schedule) => {
            let { price, comission_percentage } = schedule

            if (schedule.bundle) {
              comission_percentage = schedule.bundle.comission_type !== 'no_comission'
                ? schedule.comission_percentage : 0
              price = schedule.bundle.comission_type === 'bundle_value'
                ? schedule.discount_price : schedule.price
            }
            return total + price * comission_percentage
          }, 0)
      ), 0)

      const serviceEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.services
          .filter (service => this.filterProfessionalEarnings (service, professional))
          .filter (service => category ? service.category.id === category?.value : true)
          .reduce ((total, service) => {
            const comission_percentage = service.comission_type !== 'no_comission'
              ? service.custom_comission_percentage : 0
            const price = service.comission_type === 'bundle_value'
              ? service.discount_price : service.custom_price

            return total + price * (service.professional_id ? comission_percentage : 0)
          }, 0)
      ), 0)

      const productEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.products
          .filter (product => this.filterProfessionalEarnings (product, professional))
          .reduce (sumBusinessItemsTotal, 0)
      ), 0)

      const bundleEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.bundles
          .filter (bundle => this.filterProfessionalEarnings (bundle, professional))
          .reduce (sumBusinessItemsTotal, 0)
      ), 0)

      const tipEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.tips
          .filter (tip => this.filterProfessionalEarnings (tip, professional))
          .reduce (sumBusinessItemsTotal, 0)
      ), 0)

      billEarningsTotal = (
        scheduleEarningsTotal + serviceEarningsTotal + productEarningsTotal
        + bundleEarningsTotal + tipEarningsTotal
      )
    }

    return cutileiEarningsTotal + billEarningsTotal
  }

  static filterProfessionalEarnings (item, professional) {
    if (professional)
      return [item.professional_id, item.professional?.id].includes (professional.id)
    return true
  }

  static getTotalBillEarnings (bills) {
    if (bills.length === 0) return 0

    return bills
      .map (bill => bill.value)
      .reduce ((total, value) => total + value)
  }

  static getBusinessBillEarnings (bills) {
    if (bills.length === 0) return 0

    return bills
      .map (bill => bill.price * (1 - bill.comission_percentage))
      .reduce ((total, value) => total + value)
  }

  static getProfessionalBillEarnings (bills) {
    if (bills.length === 0) return 0

    return bills
      .map (bill => bill.price * bill.comission_percentage)
      .reduce ((total, value) => total + value)
  }
}

export default Comission

import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import AdminNavbar from '../../../../components/AdminNavbar'
import Select from '../../../../components/Inputs/Select'
import CheckBox from '../../../../components/CheckBox'
import MoneyInput from '../../../../components/Inputs/MoneyInput'
import AlertDialog from '../../../../components/AlertDialog'
import { Formik } from 'formik'
import serviceValidator from '../../../../validators/Admin/serviceValidator'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading.svg'
import '../../../../styles/calendar.css'

import {
  Container,
  Form,
  FormField,
  Title,
  Label,
  Input,
  NumberInput,
  Button,
  DangerButton,
  Row,
  InfoText,
  WarningText,
  ErrorContainer
} from './styles'

function EditService ({ history, match }) {
  const [errorMessage, setErrorMessage] = useState (null)
  const [service, setService] = useState (null)
  const [categories, setCategories] = useState ([])
  const [loading, setLoading] = useState (true)
  const [showDeleteDialog, setShowDeleteDialog] = useState (false)
  const [showRestoreDialog, setShowRestoreDialog] = useState (false)
  const [showOkDialog, setShowOkDialog] = useState (false)
  const { id: service_id } = match.params
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      const { data: categories } = await cutileiApi.get ('/service_categories', requestConfig)
      const { data: service } = await cutileiApi.get (`/services/${service_id}`, requestConfig)

      setService (service)
      setCategories (categories.map (category => ({
        label: category.name,
        value: category.id
      })))
    } catch (error) {
      console.log (error.response)
    } finally {
      setLoading (false)
    }
  }

  const deleteService = async () => {
    try {
      await cutileiApi.delete (`/services/${service_id}`, requestConfig)
      history.replace ('/admin/config')
    } catch (error) {
      console.log (error)
    }
  }

  const restoreService = async () => {
    try {
      await cutileiApi.post (`/services/${service_id}/reactivate`, {}, requestConfig)
      history.replace ('/admin/config')
    } catch (error) {
      console.log (error)
    }
  }

  const toggleDeleteDialog = () => setShowDeleteDialog (!showDeleteDialog)
  const toggleRestoreDialog = () => setShowRestoreDialog (!showRestoreDialog)
  const toggleOkDialog = () => setShowOkDialog (!showOkDialog)

  return (
    <Container>
      <AdminNavbar/>
      {loading ? <Loading/> : (
        <Formik
          validationSchema={serviceValidator}
          initialValues={{
            'number': service.number,
            'name': service.name,
            'description': service.description ?? '',
            'category': {
              label: service.category.name,
              value: service.category.id
            },
            'price': service.price,
            'cutileiService': service.cutilei_service,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const { category, ...service } = values

            try {
              await cutileiApi.put (`/services/${service_id}`, {
                serviceCategoryId: category.value,
                ...service
              }, requestConfig)

              setSubmitting (false)
              toggleOkDialog ()
            } catch (error) {
              setSubmitting (false)
              console.log (error)
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Title>Editar serviço</Title>
              <Row>
                <FormField>
                  <Input 
                    placeholder='Nome'
                    value={values.name}
                    onChange={handleChange ('name')} 
                    onBlur={handleBlur ('name')}
                  />
                  {touched.name && errors.name && (
                    <WarningText>
                      {errors.name}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Select
                    name='category'
                    placeholder='Categoria...'
                    value={values.category}
                    options={categories}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.category}
                    touched={touched.category}
                  />
                  {touched.category && errors.category && (
                    <WarningText>
                      {errors.category}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <MoneyInput
                    name='price'
                    placeholder='Preço (R$)'
                    value={values.price}
                    onValueChange={setFieldValue}
                    onBlur={handleBlur ('price')}
                  />
                  {touched.price && errors.price && (
                    <WarningText>
                      {errors.price}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <NumberInput
                    name='number'
                    placeholder='Número na lista (opcional)'
                    value={values.number}
                    onValueChange={(values, _) => setFieldValue ('number', values.floatValue)}
                    onBlur={handleBlur ('number')}
                  />
                  {touched.number && errors.number && (
                    <WarningText>
                      {errors.number}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <Input 
                    placeholder='Descrição do serviço'
                    value={values.description}
                    onChange={handleChange ('description')} 
                    onBlur={handleBlur ('description')}
                  />
                  {touched.description && errors.description && (
                    <WarningText>
                      {errors.description}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              <Row>
                <CheckBox
                  id='cutileiService'
                  style={{marginRight: 6, marginTop: 3}}
                  value={values.cutileiService}
                  onValueChange={value => setFieldValue ('cutileiService', value)}
                />
                <Label htmlFor='cutileiService'>
                  Serviço Cutilei
                </Label>
              </Row>

              {errorMessage && (
                <ErrorContainer>
                  <InfoText>{errorMessage}</InfoText>
                </ErrorContainer>
              )}

              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : 'Salvar alterações'}
              </Button>

              {service.deleted ? (
                <DangerButton type='button' onClick={toggleRestoreDialog} style={{backgroundColor: '#FFC100'}}>
                  Reativar serviço
                </DangerButton>
              ) : (
                <DangerButton type='button' onClick={toggleDeleteDialog}>
                  Excluir serviço
                </DangerButton>
              )}
            </Form>
          )}
        </Formik>
      )}
      <AlertDialog
        visible={showDeleteDialog}
        title='Atenção!'
        message='Deseja realmente excluir este serviço?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteService}
        onClose={toggleDeleteDialog}
        dangerous
      />
      <AlertDialog
        visible={showRestoreDialog}
        title='Atenção!'
        message='Deseja realmente reativar este serviço?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={restoreService}
        onClose={toggleRestoreDialog}
      />
      <AlertDialog
        visible={showOkDialog}
        title='Sucesso'
        message='Alterações salvas!'
        confirmText='Ok'
        onConfirm={() => history.replace ('/admin/config')}
        onClose={() => history.replace ('/admin/config')}
      />
    </Container>
  )
}

export default withRouter (EditService)

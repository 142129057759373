import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../../../services/cutileiApi'
import Comission from '../../../../services/Comission'
import General from '../../../../services/General'
import TimeWindow from '../../../../services/timeWindow'
import AuthService from '../../../../services/auth'
import Select from '../../../../components/Inputs/Select'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import { 
  InfoContainer,
  LinkContainer,
  FilterContainer,
  Info,
  Row,
  SubTitle,
  InfoText,
  LinkButton,
  MoneyTextMask
} from '../styles'

function Expenses () {
  const [loading, setLoading] = useState (true)
  const [schedulesByMonth, setSchedulesByMonth] = useState ([])
  const [schedulesByDay, setSchedulesByDay] = useState ([])
  const [monthExpenses, setMonthExpenses] = useState (0)
  const [businessExpenses, setBusinessExpenses] = useState (0)
  const [professionalExpenses, setProfessionalExpenses] = useState (0)
  const [month, setMonth] = useState (JSON.parse (localStorage.getItem ('month_expenses')) || {
    label: DateTime.now ().setLocale ('pt-BR').toFormat ('MMMM'),
    value: DateTime.now ().month
  })
  const [year, setYear] = useState (JSON.parse (localStorage.getItem ('year_expenses')) || {
    label: DateTime.now ().year,
    value: DateTime.now ().year
  })
  const token = AuthService.getToken ()

  useEffect (() => {
    getSchedulesByMonth ()
  }, [month, year])

  const getSchedulesByMonth = async () => {
    const filters = `?order=desc&status=validated&month=${month.value}`
                  + `&year=${year.value}&page=1&per_page=999`

    try {
      setLoading (true)

      const { data: schedules } = await cutileiApi.get (`/schedules${filters}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      const { data } = schedules
      setSchedulesByMonth (data)
      setSchedulesByDay (General.groupBy (data, 'date'))
      setMonthExpenses (Comission.getTotalEarnings (data))
      setBusinessExpenses (Comission.getBusinessEarnings (data))
      setProfessionalExpenses (Comission.getProfessionalEarnings (data))
    } catch (error) {
      console.log (error.response.data)
    } finally {
      setLoading (false)
    }
  }

  return (
    <>
      <FilterContainer>
        <Row>
          <Select
            name='month'
            placeholder='Selecionar...'
            value={month}
            options={TimeWindow.getMonths ()}
            onChange={(name, option) => {
              setMonth (option)
              localStorage.setItem ('month_expenses', JSON.stringify (option))
            }}
            containerStyles={{boxShadow: '0px 1px 10px -6px', borderRadius: 15}}
          />
          
          <Select
            name='year'
            placeholder='Selecionar...'
            value={year}
            options={TimeWindow.getYears ('2021', `${DateTime.now ().year}`)}
            onChange={(name, option) => {
              setYear (option)
              localStorage.setItem ('year_expenses', JSON.stringify (option))
            }}
            containerStyles={{boxShadow: '0px 1px 10px -6px', borderRadius: 15}}
          />
        </Row>
      </FilterContainer>
      
      {loading ? <Loading style={{marginTop: 20}}/> : (
        <>
          <InfoContainer style={{height: 160, alignItems: 'center'}}>
            <SubTitle>Total do mês</SubTitle>
            <Info style={{alignItems: 'center', height: 'auto'}}>
              <Row>
                <SubTitle> Valor total a pagar: </SubTitle>
                <MoneyTextMask value={monthExpenses}/>
              </Row>
              <Row>
                <InfoText> Comissão dos salões: </InfoText>
                <MoneyTextMask value={businessExpenses}/>
              </Row>
              <Row>
                <InfoText> Comissão dos profissionais: </InfoText>
                <MoneyTextMask value={professionalExpenses}/>
              </Row>
            </Info>
            <LinkButton to={{
              pathname: '/admin/expenses/monthly',
              state: {
                monthExpenses,
                businessExpenses,
                professionalExpenses,
                schedulesByMonth
              }
            }}>
              Detalhes
            </LinkButton>
          </InfoContainer>

          {schedulesByDay.map ((schedules, index) => {
            const day = DateTime.fromISO (schedules[0].date).day
            
            return (
              <LinkContainer
                key={index}
                style={{justifyContent: 'center', height: 50}}
                to={{
                  pathname: '/admin/expenses/daily',
                  search: `?day=${day}`,
                  state: {
                    day: day,
                    daySchedules: schedules
                  }
                }}
              >
                <Row>
                  <SubTitle>{`Dia ${day}`}</SubTitle>
                  <MoneyTextMask value={Comission.getTotalEarnings (schedules)}/>
                  <FA.FaChevronRight size={15} color='#252525'/>
                </Row>
              </LinkContainer>
            )
          })}
        </>
      )}
    </>
  )
}

export default withRouter (Expenses)

import styled from 'styled-components'
import { Link } from "react-router-dom";

export const Container = styled.div`
  margin: auto;
  background-color: #F7F7F7;
  box-shadow: 0px 2px 10px -6px #252525;
  max-width: 400px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  padding: 5px;
  margin-top: 70px;
`

export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F7F7F7;
  padding: 10px 10px 0px;
`

export const Image = styled.img`
  margin-bottom: 10px;
`

export const Title = styled.h2`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  margin-top: 10px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;

  &::placeholder {
    color: #BBBBBB
  }

  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 0px;
`

export const Button = styled.button`
  width: 150px;
  background-color: #252525;
  color: #FFFFFF;
  padding: 8px;
  border: 0px;
  border-radius: 15px;
  text-align: center;
  text-decoration: none;
  margin: 15px 0px 8px;

  &:hover {
    background-color: #121212;
    color: #FFFFFF;
  }
`

export const WarningText = styled.p`
  margin: 0px;
  color: #FF3939;
  text-align: center;
`

export const ErrorContainer = styled.div`
  padding: 9px;
  margin-top: 15px;
  width: 100%;
  border-radius: 15px;
  text-align: center;
  background-color: #FF3939;

  p {
    color: #FFFFFF;
    margin: 0px;
  }
`
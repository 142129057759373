import React from 'react'
import Product from './Product'

import {
  Container,
  SubTitle,
  LabelRow
} from './styles'

function ProductList ({ products }) {
  return (
    <Container>
      {products.length > 0 ? (
        <>
          <SubTitle>
            {products.length + ' ' + (products.length === 1 ? 'Resultado' : 'Resultados')}
          </SubTitle>
          <LabelRow>
            <SubTitle>Nome</SubTitle>
            <SubTitle>Categoria</SubTitle>
            <SubTitle style={{justifySelf: 'flex-end'}}>Status</SubTitle>
          </LabelRow>
          {products.map (product =>
            <Product key={product.id} data={product}/>
          )}
        </>
      ) : (
        <SubTitle>
          0 resultados
        </SubTitle>
      )}
      
    </Container>
  )
}

export default ProductList

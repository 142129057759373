import styled from 'styled-components'
import Cleave from 'cleave.js/react'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`

export const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
`

export const ArrowButton = styled.button`
  width: auto;
  height: auto;
  padding: 0px;
  border: 0px;
  background-color: transparent;
`

export const MaskedInput = styled(Cleave)`
  width: 100%;
  height: 38px;
  margin: 0px 5px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import AuthService from '../../services/auth'
import cutileiApi from '../../services/cutileiApi'
import viaCepApi from '../../services/viaCepApi'
import MaskedInputService from '../../services/maskedInput'
import PasswordInput from '../../components/Inputs/PasswordInput'
import AlertDialog from '../../components/AlertDialog'
import signUpValidator from '../../validators/signUpValidator'
import { Formik } from 'formik'
import { ReactComponent as ButtonLoading } from '../../icons/loading.svg'

import {
  Container,
  Form,
  Button,
  Title,
  SubTitle,
  FormField,
  WarningText,
  ErrorContainer,
  Input,
  MaskedInput,
  Row,
} from './styles'

function SignUp ({ history }) {
  require ('cleave.js/dist/addons/cleave-phone.br')

  const [loading, setLoading] = useState (true)
  const [loadingAddress, setLoadingAddress] = useState (false)
  const [addressFound, setAddressFound] = useState (false)
  const [errorMessage, setErrorMessage] = useState (null)
  const [showOkDialog, setShowOkDialog] = useState (false)
  const [services, setServices] = useState ([])

  useEffect (() => {
    getServices ()
  }, [])

  const getServices = async () => {
    try {
      const { data: cutileiServices } = await cutileiApi.get (
        '/services?status=active&cutilei_services=true'
      )
      setServices (cutileiServices.map (service => ({
        id: service.id,
        duration: service.name.toLowerCase ().includes ('pé e mão') ? 60 : 30,
        comission_percentage: 0.4
      })))
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const searchAddress = async (event, setFieldValue) => {
    const postal_code = event.target.rawValue

    setFieldValue ('postal_code', postal_code)
    
    if (postal_code.length === 8) {
      setLoadingAddress (true)
      try {
        const response = await viaCepApi.get (`/ws/${postal_code}/json`)
        const { data } = response

        setFieldValue ('route', data.logradouro)
        setFieldValue ('district', data.bairro)
        setFieldValue ('city', data.localidade)
        setFieldValue ('state', data.uf)

        setAddressFound (true)
      } catch (error) {
        console.log (error.response)
      } finally {
        setLoadingAddress (false)
      }
    }
  }

  const toggleOkDialog = () => setShowOkDialog (!showOkDialog)

  return (
    <Container>
      <Formik
        validationSchema={signUpValidator}
        initialValues={{
          'name': '',
          'corporate_name': '',
          'owner': '',
          'owner_email': '',
          'owner_phone': '',
          'email': '',
          'password': '',
          'passwordConfirmation': '',
          'cnpj': '',
          'phone': '',
          'cellphone': '',
          'route': '',
          'number': '',
          'complement': '',
          'district': '',
          'city': '',
          'state': '',
          'postal_code': ''
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const {
            name,
            corporate_name,
            owner,
            owner_email,
            owner_phone,
            email,
            password,
            passwordConfirmation,
            cnpj,
            phone,
            cellphone,
            ...address
          } = values
          
          setErrorMessage (null)
          
          try {
            const businessServices = services.map (service => { 
              return {
                id: service.id, duration: 30, comission_percentage: 0.4
              }
            })

            const { data: business } = await cutileiApi.post ('/businesses', {
              name,
              corporate_name,
              owner,
              owner_email,
              owner_phone,
              email,
              password,
              passwordConfirmation,
              cnpj,
              phone,
              cellphone,
              address
            })

            const { data: loginData } = await cutileiApi.post ('/auth/businesses', {
              email, password
            })

            await cutileiApi.post (`/businesses/${business.id}/services`, services, {
              headers: {
                'Authorization': `Bearer ${loginData.token}`
              }
            })

            setSubmitting (false)
            AuthService.signIn (loginData)
            toggleOkDialog ()
          } catch (error) {
            setSubmitting (false)
            console.log (error.response.data)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Title>Cadastrar salão</Title>
            <SubTitle>Dados do salão</SubTitle>
            <Row>
              <FormField stretch={2}>
                <Input
                  placeholder='Nome fantasia'
                  value={values.name}
                  onChange={handleChange ('name')}
                  onBlur={handleBlur ('name')}
                />
                {touched.name && errors.name && (
                  <WarningText>{errors.name}</WarningText>
                )}
              </FormField>
              <FormField stretch={2}>
                <Input
                  placeholder='Razão social'
                  value={values.corporate_name}
                  onChange={handleChange ('corporate_name')}
                  onBlur={handleBlur ('corporate_name')}
                />
                {touched.corporate_name && errors.corporate_name && (
                  <WarningText>{errors.corporate_name}</WarningText>
                )}
              </FormField>
              <FormField stretch={2}>
                <MaskedInput
                  name='cnpj'
                  placeholder='CNPJ'
                  options={{
                    blocks: [2,3,3,4,2],
                    delimiters: ['.', '.','/', '-'],
                    numericOnly: true
                  }}
                  value={values.cnpj}
                  onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                  onBlur={handleBlur ('cnpj')}
                />
                {touched.cnpj && errors.cnpj && (
                  <WarningText>{errors.cnpj}</WarningText>
                )}
              </FormField>
            </Row>
            <Row>
              <FormField stretch={2}>
                <Input
                  type='email'
                  placeholder='E-mail'
                  value={values.email}
                  onChange={handleChange ('email')}
                  onBlur={handleBlur ('email')}
                />
                {touched.email && errors.email && (
                  <WarningText>{errors.email}</WarningText>
                )}
              </FormField>
              <FormField>
                <MaskedInput
                  name='cellphone'
                  placeholder='Celular'
                  options={{
                    blocks: [0, 2, 5, 4],
                    delimiters: ['(', ') ', '-'],
                    numericOnly: true,
                  }}
                  value={values.cellphone}
                  onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                  onBlur={handleBlur ('cellphone')}
                />
                {touched.cellphone && errors.cellphone && (
                  <WarningText>{errors.cellphone}</WarningText>
                )}
              </FormField>
              <FormField>
                <MaskedInput
                  name='phone'
                  placeholder='Telefone fixo (opcional)'
                  options={{
                    blocks: [0, 2, 4, 4],
                    delimiters: ['(', ') ', '-'],
                    numericOnly: true,
                  }}
                  value={values.phone}
                  onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                  onBlur={handleBlur ('phone')}
                />
                {touched.phone && errors.phone && (
                  <WarningText>{errors.phone}</WarningText>
                )}
              </FormField>
            </Row>
            <SubTitle>Dados do responsável</SubTitle>
            <Row>
              <FormField>
                <Input
                  placeholder='Nome completo do responsável'
                  value={values.owner}
                  onChange={handleChange ('owner')}
                  onBlur={handleBlur ('owner')}
                />
                {touched.owner && errors.owner && (
                  <WarningText>{errors.owner}</WarningText>
                )}
              </FormField>
              <FormField>
                <Input
                  type='email'
                  placeholder='E-mail do responsável'
                  value={values.owner_email}
                  onChange={handleChange ('owner_email')}
                  onBlur={handleBlur ('owner_email')}
                />
                {touched.owner_email && errors.owner_email && (
                  <WarningText>{errors.owner_email}</WarningText>
                )}
              </FormField>
              <FormField>
                <MaskedInput
                  name='owner_phone'
                  placeholder='Celular do responsável'
                  options={{
                    blocks: [0, 2, 5, 4],
                    delimiters: ['(', ') ', '-'],
                    numericOnly: true,
                  }}
                  value={values.owner_phone}
                  onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                  onBlur={handleBlur ('owner_phone')}
                />
                {touched.owner_phone && errors.owner_phone && (
                  <WarningText>{errors.owner_phone}</WarningText>
                )}
              </FormField>
            </Row>
            <SubTitle>Segurança</SubTitle>
            <Row>
              <FormField>
                <PasswordInput
                  placeholder='Senha'
                  value={values.password}
                  onChange={handleChange ('password')}
                  onBlur={handleBlur ('password')}
                />
                {touched.password && errors.password && (
                  <WarningText>{errors.password}</WarningText>
                )}
              </FormField>
              <FormField>
                <PasswordInput
                  placeholder='Confirmar senha'
                  value={values.passwordConfirmation}
                  onChange={handleChange ('passwordConfirmation')}
                  onBlur={handleBlur ('passwordConfirmation')}
                />
                {touched.passwordConfirmation && errors.passwordConfirmation && (
                  <WarningText>{errors.passwordConfirmation}</WarningText>
                )}
              </FormField>
            </Row>
            <SubTitle>Endereço</SubTitle>
            <Row>
              <FormField>
                <MaskedInput
                  name='postal_code'
                  placeholder='CEP'
                  options={{
                    blocks: [5, 3],
                    delimiters: ['-'],
                    numericOnly: true
                  }}
                  value={values.postal_code}
                  onChange={event => searchAddress (event, setFieldValue)}
                  onBlur={handleBlur ('postal_code')}
                />
                {touched.postal_code && errors.postal_code && (
                  <WarningText>{errors.postal_code}</WarningText>
                )}
              </FormField>
              <FormField stretch={2}>
                <Input
                  placeholder='Rua/avenida'
                  value={values.route}
                  onChange={handleChange ('route')}
                  onBlur={handleBlur ('route')}
                  disabled
                />
              </FormField>
              <FormField>
                <Input
                  placeholder='Número'
                  value={values.number}
                  onChange={handleChange ('number')}
                  onBlur={handleBlur ('number')}
                />
                {touched.number && errors.number && (
                  <WarningText>{errors.number}</WarningText>
                )}
              </FormField>
              <FormField>
                <Input
                  placeholder='Complemento'
                  value={values.complement}
                  onChange={handleChange ('complement')}
                  onBlur={handleBlur ('complement')}
                />
              </FormField>
            </Row>
            <Row>
              <FormField stretch={2}>
                <Input
                  placeholder='Bairro'
                  value={values.district}
                  onChange={handleChange ('district')}
                  onBlur={handleBlur ('district')}
                  disabled
                />
              </FormField>
              <FormField>
                <Input
                  placeholder='Cidade'
                  value={values.city}
                  onChange={handleChange ('city')}
                  onBlur={handleBlur ('city')}
                  disabled
                />
              </FormField>
              <FormField>
                <Input
                  placeholder='Estado'
                  value={values.state}
                  onBlur={handleBlur ('state')}
                  disabled
                />
              </FormField>
            </Row>
            {errorMessage && (
              <ErrorContainer>
                <p>{errorMessage}</p>
              </ErrorContainer>
            )}
            <AlertDialog
              visible={showOkDialog}
              title={`Bem-vindo à Cutilei, ${values.name}`}
              message='Seu salão foi cadastrado com sucesso!'
              confirmText='Ok'
              onConfirm={() => history.replace ('/business')}
              onClose={() => history.replace ('/business')}
            />
            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Finalizar cadastro'}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
 
export default withRouter (SignUp)

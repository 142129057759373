import React, { useState } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import Select from '../../../components/Inputs/Select'
import { Formik } from 'formik'
import serviceValidator from '../../../validators/serviceValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import '../../../styles/calendar.css'

import {
  modalStyle,
  Form,
  FormField,
  Title,
  Input,
  Button,
  Row,
  InfoText,
  WarningText,
  ErrorContainer
} from './styles'

function AddServiceModal ({
  visible,
  serviceCategories,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const user = AuthService.getUser ()
  const token = AuthService.getToken ()
  const isBusiness = AuthService.isBusiness ()
  const businessId = isBusiness ? user.id : user.business_id

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={serviceValidator}
        initialValues={{
          'name': '',
          'category': null,
          'description': ''
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const { category, ...serviceData } = values

          try {
            const { data: service } = await cutileiApi.post ('/services', {
              serviceCategoryId: category.value,
              businessId: businessId,
              ...serviceData
            }, requestConfig)

            setSubmitting (false)
            handleConfirm (service)
            handleClose ()
          } catch (error) {
            setSubmitting (false)
            setErrorMessage (error.response?.data.message)
            console.log (error)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Title>Cadastrar serviço</Title>
            <Row>
              <FormField>
                <Input 
                  placeholder='Nome'
                  value={values.name}
                  onChange={handleChange ('name')} 
                  onBlur={handleBlur ('name')}
                />
                {touched.name && errors.name && (
                  <WarningText>
                    {errors.name}
                  </WarningText>
                )}
              </FormField>
              <FormField>
                <Select
                  name='category'
                  placeholder='Categoria...'
                  value={values.category}
                  options={serviceCategories}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  error={errors.category}
                  touched={touched.category}
                />
                {touched.category && errors.category && (
                  <WarningText>
                    {errors.category}
                  </WarningText>
                )}
              </FormField>
            </Row>
            <Row>
              <FormField>
                <Input 
                  placeholder='Descrição do serviço'
                  value={values.description}
                  onChange={handleChange ('description')} 
                  onBlur={handleBlur ('description')}
                />
                {touched.description && errors.description && (
                  <WarningText>
                    {errors.description}
                  </WarningText>
                )}
              </FormField>
            </Row>
            {errorMessage && (
              <ErrorContainer>
                <InfoText>{errorMessage}</InfoText>
              </ErrorContainer>
            )}
            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Criar serviço'}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default AddServiceModal

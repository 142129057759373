import React from 'react'
import { withRouter } from 'react-router-dom'
import Comission from '../../../../../services/Comission'
import AdminNavbar from '../../../../../components/AdminNavbar'

import { 
  Container, 
  InfoText, 
  InfoContainer,
  Row,
  Info,
  SubTitle,
  MoneyTextMask
} from '../../styles'

function AdminDailyExpenses ({ history }) {
  const { day, daySchedules } = history.location.state
  const dailyTotalExpenses = Comission.getTotalEarnings (daySchedules)
  const dailyBusinessExpenses = Comission.getBusinessEarnings (daySchedules)
  const dailyProfessionalExpenses = Comission.getProfessionalEarnings (daySchedules)

  return (
    <Container>
      <AdminNavbar/>
      <InfoContainer style={{height: 125, alignItems: 'center'}}>
        <SubTitle> Total do dia {day} </SubTitle>
        <Info style={{alignItems: 'center', height: 'auto'}}>
          <Row>
            <SubTitle> Despesa total: </SubTitle>
            <MoneyTextMask value={dailyTotalExpenses}/>
          </Row>
          <Row>
            <InfoText> Comissão dos salões: </InfoText>
            <MoneyTextMask value={dailyBusinessExpenses}/>
          </Row>
          <Row>
            <InfoText> Comissão dos profissionais: </InfoText>
            <MoneyTextMask value={dailyProfessionalExpenses}/>
          </Row>
        </Info>
      </InfoContainer>

      {daySchedules.map ((schedule, index) => {
        const { business, service, comission_percentage } = schedule

        return (
          <InfoContainer key={index}>
            <Row style={{justifyContent: 'center'}}>
              <SubTitle>
                {business.name}:
              </SubTitle>
              <MoneyTextMask value={service.price}/>
            </Row>
            <Row>
              <Row>
                <InfoText> Comissão do salão: </InfoText>
                <MoneyTextMask value={(service.price * (1 - comission_percentage))}/>
              </Row>
              <Row>
                <InfoText> Comissão dos profissionais: </InfoText>
                <MoneyTextMask value={(service.price * comission_percentage)}/>
              </Row>
            </Row>
          </InfoContainer>
        )
      })}
    </Container>
  )
}

export default withRouter (AdminDailyExpenses)

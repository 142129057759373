import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../../../services/cutileiApi'
import AdminFinancial from '../../../../services/AdminFinancial'
import Comission from '../../../../services/Comission'
import General from '../../../../services/General'
import TimeWindow from '../../../../services/timeWindow'
import AuthService from '../../../../services/auth'
import Select from '../../../../components/Inputs/Select'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import { 
  InfoContainer,
  LinkContainer,
  FilterContainer,
  Info,
  Row,
  SubTitle,
  InfoText,
  MoneyTextMask
} from '../styles'

function Earnings () {
  const [loading, setLoading] = useState (true)
  const [bills, setBills] = useState ([])
  const [billSByDay, setBillsByDay] = useState ([])
  const [totalEarnings, setTotalEarnings] = useState (0)
  const [monthExpenses, setMonthExpenses] = useState (0)
  const [schedulesByDay, setSchedulesByDay] = useState ([])

  const [month, setMonth] = useState (JSON.parse (localStorage.getItem ('month_earnings')) || {
    label: DateTime.now ().setLocale ('pt-BR').toFormat ('MMMM'),
    value: DateTime.now ().month
  })
  const [year, setYear] = useState (JSON.parse (localStorage.getItem ('year_earnings')) || {
    label: DateTime.now ().year,
    value: DateTime.now ().year
  })
  const [status, setStatus] = useState (JSON.parse (localStorage.getItem ('bill_status')) || {
    label: 'Pagas',
    value: 'paid'
  })
  const token = AuthService.getToken ()

  const STATUS_LIST = [
    {label: 'Pagas', value: 'paid'},
    {label: 'A pagar', value: 'pending'},
    {label: 'Canceladas', value: 'canceled'}
  ]

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [month, year, status])

  const getData = async () => {
    setLoading (true)

    await getBills ()
    await getSchedulesByMonth ()

    setLoading (false)
  }

  const getBills = async () => {
    try {
      const { data: bills} = await cutileiApi.get (
        `/bills?month=${month.value}&year=${year.value}&status=${status.value}`,
        requestConfig
      )
      
      setBills (bills)
      setTotalEarnings (AdminFinancial.getAdminEarnings (bills))
      setBillsByDay (General.groupBy (bills.map (bill => ({
        ...bill, day: DateTime.fromISO (bill.created_at).toISODate ()
      })), 'day'))
    } catch (error) {
      console.log (error.response)
    }
  }

  const getSchedulesByMonth = async () => {
    const filters = `?order=desc&status=validated&month=${month.value}`
                  + `&year=${year.value}&page=1&per_page=9999`

    try {
      const { data: schedules } = await cutileiApi.get (`/schedules${filters}`, requestConfig)

      const { data } = schedules
      setMonthExpenses (Comission.getTotalEarnings (data))
      setSchedulesByDay (General.groupBy (data, 'date'))
    } catch (error) {
      console.log (error.response.data)
    }
  }

  return (
    <>
      <FilterContainer>
        <Row>
          <Select
            name='month'
            placeholder='Mês...'
            value={month}
            options={TimeWindow.getMonths ()}
            onChange={(name, option) => {
              setMonth (option)
              localStorage.setItem ('month_earnings', JSON.stringify (option))
            }}
            containerStyles={{boxShadow: '0px 1px 10px -6px', borderRadius: 15}}
          />
          <Select
            name='year'
            placeholder='Ano...'
            value={year}
            options={TimeWindow.getYears ('2021', `${DateTime.now ().year}`)}
            onChange={(name, option) => {
              setYear (option)
              localStorage.setItem ('year_earnings', JSON.stringify (option))
            }}
            containerStyles={{boxShadow: '0px 1px 10px -6px', borderRadius: 15}}
          />
          <Select
            name='status'
            placeholder='Status...'
            value={status}
            options={STATUS_LIST}
            onChange={(name, option) => {
              setStatus (option)
              localStorage.setItem ('bill_status', JSON.stringify (option))
            }}
            containerStyles={{boxShadow: '0px 1px 10px -6px', borderRadius: 15}}
          />
        </Row>
      </FilterContainer>
      
      {loading ? <Loading style={{marginTop: 20}}/> : (
        <>
          {status.value === 'paid' ? (
            <InfoContainer style={{height: 120, alignItems: 'center'}}>
              <SubTitle>Total do mês</SubTitle>
              <Info style={{alignItems: 'center', height: 'auto'}}>
                <Row>
                  <InfoText>Receita total bruta: </InfoText>
                  <MoneyTextMask value={totalEarnings}/>
                </Row>
                <Row>
                  <InfoText>Despesas com salões: </InfoText>
                  <MoneyTextMask value={monthExpenses}/>
                </Row>
                <Row>
                  <SubTitle>Receita deduzida de despesas: </SubTitle>
                  <MoneyTextMask value={(totalEarnings - monthExpenses)}/>
                </Row>
              </Info>
            </InfoContainer>
          ) : null}

          {billSByDay.map ((bills, index) => {
            const day = DateTime.fromISO (bills[0].day).day
            
            return (
              <LinkContainer
                key={index}
                style={{justifyContent: 'center', height: 50}}
                to={{
                  pathname: '/admin/earnings/daily',
                  search: `?day=${day}`,
                  state: {
                    day: day,
                    dayBills: bills,
                    daySchedules: schedulesByDay.filter (schedules => DateTime.fromISO (schedules[0].date).day === day)
                  }
                }}
              >
                <Row>
                  <SubTitle>{`Dia ${day}`}</SubTitle>
                  <MoneyTextMask value={AdminFinancial.getTotalBillEarnings (bills)}/>
                  <FA.FaChevronRight size={15} color='#252525'/>
                </Row>
              </LinkContainer>
            )
          })}
        </>
      )}
    </>
  )
}

export default withRouter (Earnings)

import React, { useState, useEffect } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import AttachmentList from '../../../../components/Lists/AttachmentList'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'

import {
  Container,
} from '../styles'

function CustomerAttachments ({ customer }) {
  const [loading, setLoading] = useState (true)
  const [attachments, setAttachments] = useState ([])
  const user = AuthService.getUser ()
  const token = AuthService.getToken ()
  const isBusiness = AuthService.isBusiness ()
  const business_id = isBusiness ? user.id : user.business_id

  useEffect (() => {
    getAttachments ()
  }, [])

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const getAttachments = async () => {
    try {
      const { data: attachments } = await cutileiApi.get (
        `/businesses/${business_id}/attachments?customer_id=${customer.id}`,
        requestConfig
      )
      setAttachments (attachments)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Container style={{paddingInline: 10}}>
      {loading ? <Loading style={{marginBottom: 20}}/> : (
        <AttachmentList
          attachments={attachments}
          customerID={customer.id}
          onAttachmentAdded={attachment => setAttachments ([attachment, ...attachments])}
          onDelete={
            attachment => setAttachments (attachments.filter (a => a.id !== attachment.id))
          }
        />
      )}
    </Container>
  )
}

export default CustomerAttachments

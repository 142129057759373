import React, { useState } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import AlertDialog from '../../../AlertDialog'
import NumberInput from '../../../Inputs/NumberInput'
import MoneyInput from '../../../Inputs/MoneyInput'
import Select from '../../../Inputs/Select'
import billBundleValidator from '../../../../validators/billBundleValidator'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading2.svg'
import { Formik } from 'formik'
import * as FA from 'react-icons/fa'


import {
  Container,
  FormField,
  Row,
  Input,
  InfoText,
  MoneyTextMask,
  Button
} from './styles'

function BillBundle ({
  bill,
  data: bundle,
  professionals,
  enableEditting = true,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  const [editting, setEditting] = useState (false)
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [errorMessage, setErrorMessage] = useState (null)
  const [deleting, setDeleting] = useState (false)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const deleteBundle = async () => {
    setDeleting (true)
    try {
      await cutileiApi.delete (`/business_bills/${bill.id}/bundles/${bundle.id}`, requestConfig)
      setDeleting (false)
      handleDelete (bundle)
    } catch (error) {
      setDeleting (false)
      if (error.response.status === 403) setErrorMessage (error.response.data.message)
      console.log (error)
    }
  }

  const toggleEditting = () => setEditting (!editting)
  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)

  return editting ? (
    <Formik
      validationSchema={billBundleValidator}
      initialValues={{
        'bundle': bundle,
        'professional': bundle.professional ? {
          label: bundle.professional.nickname || bundle.professional.name.split (' ')[0],
          value: bundle.professional.id
        } : null,
        'amount': bundle.amount,
        'custom_price': bundle.custom_price,
        'custom_comission_percentage': bundle.custom_comission_percentage * 100
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const bundleData = {
          professional_id: values.professional?.value || null,
          amount: values.amount,
          custom_price: values.custom_price,
          custom_comission_percentage: values.custom_comission_percentage / 100
        }

        try {
          const { data: editedBundle } = await cutileiApi.put (
            `/business_bills/${bill.id}/bundles/${bundle.id}`, bundleData, requestConfig
          )

          setSubmitting (false)
          setEditting (false)
          handleEdit (editedBundle)
        } catch (error) {
          if (error.response.data) setErrorMessage (error.response.data.message)
          setSubmitting (false)
          console.log (error)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleBlur, 
        handleSubmit
      }) => (
        <Container editting={editting} enableEditting={enableEditting}>
          <FormField>
            <Input
              name='bundle'
              placeholder='Pacote...'
              value={values.bundle.name}
              style={{width: '100%'}}
              disabled
            />
          </FormField>
          <FormField>
            <Select
              name='professional'
              placeholder='Profissional...'
              value={values.professional}
              options={professionals.map (professional => ({
                label: professional.nickname || professional.name.split (' ')[0], value: professional.id
              }))}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.professional}
              touched={touched.professional}
              containerStyles={{width: '100%'}}
            />
          </FormField>
          <FormField>
            <NumberInput
              id='amount'
              name='amount'
              value={values.amount}
              minValue={1}
              maxValue={99}
              onChange={setFieldValue}
              style={{width: '100%', marginLeft: -8}}
            />
          </FormField>
          <FormField>
            <MoneyInput
              name='custom_price'
              placeholder='Preço'
              value={values.custom_price}
              onValueChange={setFieldValue}
              onBlur={handleBlur ('custom_price')}
              style={{width: '100%'}}
            />
          </FormField>
          <FormField>
            <NumberInput
              id='custom_comission_percentage'
              name='custom_comission_percentage'
              value={values.custom_comission_percentage}
              minValue={0}
              maxValue={100}
              onChange={setFieldValue}
              style={{width: '100%', marginLeft: -10}}
            />
          </FormField>
          <Row>
            <Button type='button' onClick={handleSubmit} disabled={isSubmitting}>
              {isSubmitting ? (
                <ButtonLoading style={{margin: 0}}/>
              ) : (
                <FA.FaSave color='#35A7FF' size={16}/>
              )}
            </Button>
            <Button type='button' onClick={toggleEditting}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </Button>
          </Row>
          {/* {errorMessage && (
            <ErrorContainer>
              <InfoText>{errorMessage}</InfoText>
            </ErrorContainer>
          )} */}
        </Container>
      )}
    </Formik>
  ) : (
    <Container editting={editting} enableEditting={enableEditting}>
      <InfoText>
        {bundle.name}
      </InfoText>
      <InfoText>
        {bundle.professional
          ? bundle.professional.nickname || bundle.professional.name.split (' ')[0]
          : '-'
        }
      </InfoText>
      <InfoText>
        {bundle.amount}
      </InfoText>
      <MoneyTextMask value={bundle.custom_price ?? 0} style={{justifySelf: 'center'}}/>
      <InfoText>
        {bundle.custom_comission_percentage * 100}%
      </InfoText>
      {enableEditting && (
        <Row style={{justifySelf: 'center'}}>
          <Button onClick={toggleEditting}>
            <FA.FaEdit color='#35A7FF' size={18}/>
          </Button>
          <Button onClick={toggleAlertDialog}>
            {deleting ? (
              <ButtonLoading style={{margin: 0}}/>
            ) : (
              <FA.FaTrash color='#FF3939' size={16}/>
            )}
          </Button>
        </Row>
      )}
      <AlertDialog
        visible={showAlertDialog}
        title='Atenção!'
        message='Deseja realmente remover este pacote da comanda?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteBundle}
        onClose={toggleAlertDialog}
        dangerous
      />
      <AlertDialog
        visible={errorMessage !== null}
        title='Erro'
        message={errorMessage}
        confirmText='Ok'
        onConfirm={() => setErrorMessage (null)}
        onClose={() => setErrorMessage (null)}
        containerStyle={{width: 480}}
      />
    </Container>
  )
}

export default BillBundle

import React, { useState, useEffect } from 'react'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import Select from '../../../components/Inputs/Select'
import ClearableInput from '../../../components/Inputs/ClearableInput'
import BusinessProductList from '../../../components/Lists/BusinessProductList'
import AddProductsModal from '../../Modals/BusinessProductsModal'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { DateTime } from 'luxon'

import {
  Container,
  Row,
  Button
} from '../styles'

function EditProducts () {
  const [loading, setLoading] = useState (true)
  const [products, setProducts] = useState ([])
  const [categories, setCategories] = useState ([])
  const [category, setCategory] = useState (null)
  const [name, setName] = useState ('')
  const [showAddProductsModal, setShowAddProductsModal] = useState (false)
  const user = AuthService.getUser ()
  const token = AuthService.getToken ()
  const isBusiness = AuthService.isBusiness ()
  const businessId = isBusiness ? user.id : user.business_id

  useEffect (() => {
    getData ()
  }, [])

  useEffect (() => {
    getProducts ()
  }, [category, name])

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const getData = async () => {
    try {
      setLoading (true)

      const { data: categories } = await cutileiApi.get ('/product_categories', requestConfig)

      setCategories (categories.map (category => ({
        label: category.name,
        value: category.id
      })))

      await getProducts ()
    } catch (error) {
      console.log (error.response)
    } finally {
      setLoading (false)
    }
  }

  const getProducts = async () => {
    let filters = ''
    if (name) filters += `&name=${name}`
    if (category) filters += `&category_id=${category.value}`
    
    try {
      setLoading (true)

      const { data: products } = await cutileiApi.get (
        `/businesses/${businessId}/products?status=active`, requestConfig
      )

      setProducts (products)
    } catch (error) {
      console.log (error.response)
    } finally {
      setLoading (false)
    }
  }

  const compareProducts = (a, b) => (
    DateTime.fromISO (a.created_at).toMillis () - DateTime.fromISO (b.created_at).toMillis ()
  )

  const toggleAddProductsModal = () => setShowAddProductsModal (!showAddProductsModal)

  return (
    <Container>
      <Row style={{gap: 10, width: 600}}>
        <Select
          name='category'
          placeholder='Categoria...'
          value={category}
          options={categories}
          isClearable={true}
          onChange={(_, option) => setCategory (option)}
          containerStyles={{boxShadow: '0px 1px 10px -7px', borderRadius: 15, height: 38}}
        />
        <ClearableInput
          placeholder='Pesquisar por nome'
          value={name}
          onChange={e => setName (e.target.value)}
          onClear={() => setName ('')}
          inputStyle={{boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
        />
      </Row>
      <Button style={{marginBottom: 20}} onClick={toggleAddProductsModal}>
        Adicionar produtos
      </Button>
      {loading ? <Loading/> : (
        <>
          <BusinessProductList
            products={products}
            onEdit={product => setProducts (products.map (p => p.id === product.id ? {...product} : p))}
            onDelete={deletedProduct => setProducts (products.filter (p => p.id !== deletedProduct.id))}
          />
          <AddProductsModal
            visible={showAddProductsModal}
            onConfirm={addedProduct => setProducts (
              [...products, addedProduct].sort (compareProducts)
            )}
            onClose={toggleAddProductsModal}
          />
        </>
      )}
    </Container>
  )
}

export default EditProducts

class Auth {
  static signIn (data) {
    localStorage.setItem ('token', data.token)
    if (data.business)
      localStorage.setItem ('business', JSON.stringify (data.business))
    else if (data.professional)
      localStorage.setItem ('professional', JSON.stringify (data.professional))
    else if (data.admin)
      localStorage.setItem ('admin', JSON.stringify (data.admin))
  }

  static signOut () {
    localStorage.clear ()
  }

  static getToken () {
    return localStorage.getItem ('token')
  }

  static isSigned () {
    return this.getToken () !== null
  }

  static isAdmin () {
    return localStorage.getItem ('admin') !== null
  }

  static isBusiness () {
    return localStorage.getItem ('business') !== null
  }

  static isProfessional () {
    return localStorage.getItem ('professional') !== null
  }

  static getUser () {
    if (this.isBusiness ())
      return JSON.parse (localStorage.getItem ('business'))
    else if (this.isProfessional ())
      return JSON.parse (localStorage.getItem ('professional'))
    else if (this.isAdmin ())
      return JSON.parse (localStorage.getItem ('admin'))
  }

  static updateUser (data) {
    if (data.business)
      localStorage.setItem ('business', JSON.stringify (data.business))
    else if (data.professional)
      localStorage.setItem ('professional', JSON.stringify (data.professional))
  }
}

export default Auth

import React, { useState } from 'react'
import Modal from 'react-modal'
import CustomerPersonalInfo from './PersonalInfo'
import CustomerSchedules from './Schedules'
import CustomerBills from './Bills'
import CustomerProducts from './Products'
import CustomerCredits from './Credits'
import CustomerBundles from './Bundles'
import CustomerRecord from './Record'
import CustomerAttachments from './Attachments'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  TabContainer,
  CloseButton,
  TabButton,
  Title,
  Row
} from './styles'

function CustomerModal ({
  visible,
  data: customer,
  onClose: handleClose
}) {
  const [option, setOption] = useState ('personal')

  const OPTION_LIST = [
    {label: 'Dados pessoais', value: 'personal', component: <CustomerPersonalInfo customer={customer}/>},
    {label: 'Agendamentos', value: 'schedules', component: <CustomerSchedules customer={customer}/>},
    {label: 'Comandas', value: 'bills', component: <CustomerBills customer={customer}/>},
    {label: 'Produtos', value: 'products', component: <CustomerProducts customer={customer}/>},
    {label: 'Créditos', value: 'credits', component: <CustomerCredits customer={customer}/>},
    {label: 'Pacotes', value: 'packages', component: <CustomerBundles customer={customer}/>},
    {label: 'Prontuário', value: 'record', component: <CustomerRecord customer={customer}/>},
    {label: 'Anexos', value: 'attachments', component: <CustomerAttachments customer={customer}/>}
  ]

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    > 
      <TabContainer>
        <Row>
          <Title>{customer.name}</Title>
          <CloseButton onClick={handleClose}>
            <FA.FaTimes color='#FF3939' size={18}/>
          </CloseButton>
        </Row>
        <Row style={{paddingInline: 25}}>
          {OPTION_LIST.map ((o, index) =>
            <TabButton
              key={index}
              selected={option === o.value}
              color={option === o.value ? '#FFC100' : '#FFFFFF'}
              onClick={() => {setOption (o.value)}}
            >
              {o.label}
            </TabButton>
          )}
        </Row>
      </TabContainer>
      {OPTION_LIST.find (o => o.value === option).component}
    </Modal>
  )
}

export default CustomerModal

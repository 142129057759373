import React from 'react'
import Logo from '../../../../icons/logo-cutilei-simple.png'

import {
  Container,
  Image,
  Row,
  SubTitle,
  InfoText,
  MoneyTextMask
} from './styles'

function Service ({
  data: service,
}) {
  return (
    <Container to={{
      pathname: `/admin/services/${service.id}/edit`,
      state: {service}
    }}>
      <Row>
        {service.cutilei_service && <Image src={Logo} alt='Service Cutilei'/>}
        <SubTitle>
          {service.name}
        </SubTitle>
      </Row>
      <InfoText>
        {service.category.name}
      </InfoText>
      <MoneyTextMask value={service.price} style={{justifySelf: 'center'}}/>
      <SubTitle style={{
        color: service.deleted ? '#FF3939' : '#44CF6C',
        justifySelf: 'flex-end'
      }}>
        {service.deleted ? 'Inativo' : 'Ativo'}
      </SubTitle>
    </Container>
  )
}

export default Service

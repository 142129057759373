import styled from 'styled-components'

export const Container = styled.div`
  min-width: 720px;
  width: 840px;
`

export const LabelRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
  gap: 10px;
  justify-content: center;
  align-items: flex-end;
  justify-items: center;
  margin: 0px 20px;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 16px;
  text-align: center;
`

export const Title = styled(InfoText)`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
`

export const SubTitle = styled(Title)`
  font-size: 14px;
  margin-bottom: 10px;
`

export default class Payment {
  static groupBy (payments, key) {
    return payments.reduce ((payment, index) => {
      (payment[index[key]] = payment[index[key]] || []).push (index)
      return payment
    }, {})
  }

  static getTotalValue (payments) {
    if (payments.length === 0) return 0
    
    return payments
      .map (payment => payment.value)
      .reduce ((total, value) => total + value)
  }

  static getTotalValueByBankAccount (payments, bankAccount) {
    let filteredPayments = payments.filter (payment => 
      payment.bank_account?.id === bankAccount.id
    )

    if (filteredPayments.length === 0) return 0
    
    return filteredPayments
      .map (payment => payment.value)
      .reduce ((total, value) => total + value)
  }

  static getTotalValueByCardFlag (payments, cardFlag) {
    let filteredPayments = payments.filter (payment => 
      payment.card_flag?.codename === cardFlag
    )

    if (filteredPayments.length === 0) return 0
    
    return filteredPayments
      .map (payment => payment.value)
      .reduce ((total, value) => total + value)
  }
}

import * as Yup from 'yup'

const passwordValidator = Yup.object ({
  password: Yup.string ()
    .required ('Digite uma senha')
    .min (8, 'A senha deve conter no mínimo 8 caracteres'),

  passwordConfirmation: Yup.string ()
    .required ('Confirme a senha')
    .oneOf ([Yup.ref ('password'), null], 'As duas senhas devem ser iguais'),
})

export default passwordValidator

import React from 'react'
import Logo from '../../../icons/logo-cutilei-simple.png'
import NumberInput from '../../Inputs/NumberInput'
import MoneyInput from '../../Inputs/MoneyInput'
import CheckBox from '../../CheckBox'
import ReactTooltip from 'react-tooltip'

import {
  Container,
  DataGrid,
  FormGrid,
  FormField,
  Row,
  Image,
  Input,
  Label,
  SubTitle,
  InfoText,
  WarningText
} from './styles'

function ServiceForm ({
  data: service,
  index,
  formikProps
}) {
  const { values, touched, errors, setFieldValue, handleChange, handleBlur } = formikProps
  const selected = values.services[index]?.selected

  return (
    <Container selected={selected}>
      <DataGrid onClick={() => setFieldValue (`services[${index}].selected`, !selected)}>
        <Row>
          <CheckBox
            id={`services[${index}].selected`}
            value={values.services[index]?.selected}
            onValueChange={value => setFieldValue (`services[${index}].selected`, value)}
          />
          <Row>
            {service.cutilei_service && <Image src={Logo} alt='Service Cutilei'/>}
            <SubTitle>
              {service.name}
            </SubTitle>
          </Row>
        </Row>
        <InfoText>
          {service.category.name}
        </InfoText>
      </DataGrid>
      {selected && (
        <FormGrid>
          <FormField>
            <InfoText style={{marginBottom: 3}}>
              Apelido
            </InfoText>
            <Input
              placeholder='Apelido (opcional)'
              value={values.services[index]?.nickname}
              onChange={handleChange (`services.${index}.nickname`)} 
              onBlur={handleBlur (`services.${index}.nickname`)}
              style={{marginTop: 0}}
            />
          </FormField>
          <FormField>
            <InfoText style={{marginBottom: 3}}>
              Preço
            </InfoText>
            <MoneyInput
              name={`services[${index}].price`}
              placeholder='(R$)'
              value={values.services[index]?.price}
              onValueChange={(name, value) => setFieldValue (name, value)}
              onBlur={handleBlur (`services.${index}.price`)}
            />
            {touched.services?.[index]?.price && errors.services?.[index]?.price && (
              <WarningText>
                {errors.services[index]?.price}
              </WarningText>
            )}
          </FormField>
          <FormField>
            <InfoText>
              % Comissão
            </InfoText>
            <NumberInput
              name={`services.${index}.comission_percentage`}
              value={values.services[index]?.comission_percentage}
              minValue={values.services[index]?.cutilei_service ? 40 : 0}
              maxValue={100}
              onChange={(name, value) => setFieldValue (name, value)}
            />
          </FormField>
          <FormField>
            <InfoText>
              Duração (mins)
            </InfoText>
            <NumberInput
              name={`services.${index}.duration`}
              value={values.services[index]?.duration}
              minValue={10}
              onChange={(name, value) => setFieldValue (name, value)}
            />
          </FormField>
          <FormField>
            <InfoText>
              Tolerância (mins)
            </InfoText>
            <NumberInput
              name={`services.${index}.max_delay`}
              value={values.services[index]?.max_delay}
              minValue={5}
              maxValue={20}
              onChange={(name, value) => setFieldValue (name, value)}
              data-for={`services.${index}.max_delay`}
              data-tip
            />
            <ReactTooltip
              id={`services.${index}.max_delay`}
              effect='solid'
              place='bottom'
              backgroundColor='#252525'
              tooltipRadius='10'
            >
              <Label style={{width: 200}}>
                A tolerância de atraso para serviços pode ser configurada entre 5 e 20 minutos.
              </Label>
            </ReactTooltip>
          </FormField>
        </FormGrid>
      )}
    </Container>
  )
}

export default ServiceForm

import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: inline-block;
  background-color: #FFFFFF;
  width: 60px;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  z-index: 3;

  &:hover {
    background-color: #F7F7F7;
  }

  &:hover div {display: block}

  div {
    background: #212529;
    display: none;
    position: absolute;
    top: 83px;
    right: -70px;
    min-width: 200px;
    box-shadow: 0px 2px 15px -8px #252525;
    border-radius: 15px;

    &:before {
      content: '';
      background: #CCCCCC;
      position: absolute;
      top: -4px;
      left: 95px;
      width: 10px;
      height: 10px;
      z-index: -1;
      transform: rotate(45deg);
    }

    &:after {
      content: '';
      background: transparent;
      position: absolute;
      top: -80px;
      left: 60px;
      width: 75px;
      height: 85px;
      z-index: -5;
    }
  }
`;

export const Option = styled.button`
  background-color: #FFFFFF;
  display: block;
  padding: 10px;
  border: none;
  box-shadow: none;
  width: 100%;
  z-index: -1;

  &:hover {
    background-color: #F7F7F7;
  }

  &:first-child {
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
  }

  &:last-child {
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 55px;
  border-radius: 10px;
  object-fit: cover;
`

export const InfoText = styled.p`
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin: 3px 5px 5px;
`

export const SmallText = styled(InfoText)`
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
`

import React, { useState } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import AlertDialog from '../../../AlertDialog'
import Logo from '../../../../icons/logo-cutilei-simple.png'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'


import {
  Container,
  Image,
  Row,
  SubTitle,
  InfoText,
  MoneyTextMask,
  Button
} from './styles'

function BusinessService ({
  data: service,
  professionalID,
  onEdit,
  onDelete: handleDelete,
}) {
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [deleting, setDeleting] = useState (false)
  const user = AuthService.getUser ()
  const token = AuthService.getToken ()
  const isBusiness = AuthService.isBusiness ()
  const business_id = isBusiness ? user.id : user.business_id

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const handleEdit = () => onEdit (service)

  const deleteService = async () => {
    setDeleting (true)

    const url = professionalID
      ? `/professionals/${professionalID}/services/${service.id}`
      : `/businesses/${business_id}/services/${service.id}`

    try {
      await cutileiApi.delete (url, requestConfig)
      setDeleting (false)
      handleDelete (service)
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)

  return (
    <Container>
      <Row>
        {service.cutilei_service && <Image src={Logo} alt='Service Cutilei'/>}
        <SubTitle>
          {service.nickname || service.name}
        </SubTitle>
      </Row>
      <InfoText>
        {service.duration} min
      </InfoText>
      <InfoText>
        {service.max_delay} min
      </InfoText>
      <InfoText>
        {service.comission_percentage * 100}%
      </InfoText>
      <MoneyTextMask value={service.price ?? 0} style={{justifySelf: 'center'}}/>
      <Row style={{justifySelf: 'center'}}>
        <Button onClick={handleEdit}>
          <FA.FaEdit color='#35A7FF' size={18}/>
        </Button>
        {!service.cutilei_service && (
          <Button onClick={toggleAlertDialog}>
            {deleting ? (
              <ButtonLoading style={{margin: 0}}/>
            ) : (
              <FA.FaTrash color='#FF3939' size={16}/>
            )}
          </Button>
        )}
      </Row>
      <AlertDialog
        visible={showAlertDialog}
        title='Atenção!'
        message='Deseja realmente excluir este serviço?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteService}
        onClose={toggleAlertDialog}
        dangerous
      />
    </Container>
  )
}

export default BusinessService

import React, { useState } from 'react'
import BusinessProduct from './BusinessProduct'
import EditProductModal from '../../../pages/Modals/EditProductModal'

import {
  Container,
  SubTitle,
  InfoText,
  LabelRow
} from './styles'

function BusinessProductList ({
  products,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  const [productData, setProductData] = useState (null)
  const [showEditProductModal, setShowEditProductModal] = useState (false)
  
  const editProduct = product => {
    setProductData (product)
    toggleEditProductModal ()
  }

  const toggleEditProductModal = () => setShowEditProductModal (!showEditProductModal) 

  return (
    <Container>
      {products.length > 0 ? (
        <>
          <LabelRow>
            <SubTitle>Nome</SubTitle>
            <SubTitle>Estoque</SubTitle>
            <SubTitle>Preço</SubTitle>
            <SubTitle>Comissão de venda</SubTitle>
            <SubTitle>Ações</SubTitle>
          </LabelRow>
          {products.map (product =>
            <BusinessProduct
              key={product.id}
              data={product}
              onEdit={editProduct}
              onDelete={handleDelete}
            />
          )}
          <EditProductModal
            visible={showEditProductModal}
            data={productData}
            onClose={toggleEditProductModal}
            onConfirm={product => handleEdit (product)}
          />
        </>
      ) : (
        <InfoText>Você ainda não possui produtos cadastrados</InfoText>
      )}
    </Container>
  )
}

export default BusinessProductList

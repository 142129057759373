import React, { useState, useEffect } from 'react'
import AuthService from '../../services/auth'
import cutileiApi from '../../services/cutileiApi'
import TimeWindow from '../../services/timeWindow'
import Navbar from '../../components/Navbar'
import ClearableInput from '../../components/Inputs/ClearableInput'
import ScheduleList from '../../components/Lists/ScheduleList'
import Select from '../../components/Inputs/Select'
import ScheduleDetailsModal from '../../pages/Modals/ScheduleDetailsModal'
import EditBillModal from '../../pages/Financial/EditBillModal'
import { ReactComponent as Loading } from '../../icons/loading2.svg'
import { DateTime } from 'luxon'

import {
  Container,
  FilterContainer
} from './styles'

function Schedules () {
  const [loading, setLoading] = useState (true)
  const [query, setQuery] = useState ('')
  const [page, setPage] = useState (1)
  const [lastPage, setLastPage] = useState (0)
  const [total, setTotal] = useState (0)
  const [schedules, setSchedules] = useState ([])
  const [scheduleData, setScheduleData] = useState (null)
  const [billData, setBillData] = useState (null)
  const [professionals, setProfessionals] = useState ([])
  const [status, setStatus] = useState ({label: 'Todos', value: 'all'})
  const [professional, setProfessional] = useState (JSON.parse (localStorage.getItem ('professional_schedules')))
  const [month, setMonth] = useState (JSON.parse (localStorage.getItem ('business_month_schedules')))
  const [year, setYear] = useState (JSON.parse (localStorage.getItem ('business_year_schedules')))
  const [showScheduleDetailsModal, setShowScheduleDetailsModal] = useState (false)
  const [showEditBillModal, setShowEditBillModal] = useState (false)
  const user = AuthService.getUser ()
  const token = AuthService.getToken ()
  const isBusiness = AuthService.isBusiness ()
  const businessId = isBusiness ? user.id : user.business_id

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  useEffect (() => {
    getSchedules (1)
  }, [query, status, month, year, professional])

  const getData = async () => {
    try {
      const { data: professionals } = await cutileiApi.get (
        `/businesses/${businessId}/professionals?access_level=service`,
        requestConfig  
      )

      setProfessionals (professionals.map (professional => ({
        label: professional.nickname ? professional.nickname : professional.name,
        value: professional.id,
      })))
    } catch (error) {
      console.log (error.response)
    }
  }

  const getSchedules = async (pageNumber = page, _query = query)  => {
    if (lastPage && pageNumber > lastPage) return
    let statusFilters = '&canceled=false'
    if (status.value === 'canceled') statusFilters = '&canceled=true'
    else if (status.value === 'all') statusFilters = '&canceled=all'
    
    const filters = `&query=${_query}&month=${month?.value || ''}&year=${year?.value || ''}`
                  + `&professional_id=${professional?.value || ''}&order=desc${statusFilters}`

    try {
      const { data: schedulesResponse } = await cutileiApi.get (
        `/schedules?page=${pageNumber}&per_page=25${filters}`, requestConfig
      )
      const { data, meta } = schedulesResponse

      pageNumber > 1 ? setSchedules ([...schedules, ...data]) : setSchedules (data)
      setPage (pageNumber + 1)
      setLastPage (meta.last_page)
      setTotal (meta.total)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const compareSchedules = (a, b) => {
    const AstartTime = a.start_time || a.time
    const BstartTime = b.start_time || b.time

    return DateTime.fromISO (AstartTime).toMillis () - DateTime.fromISO (BstartTime).toMillis ()
  }

  const handleEditSchedule = schedule => {
    setSchedules ([schedule, schedules.filter (s => s.id !== schedule.id)].sort (compareSchedules))
  }

  const handleDeleteSchedule = schedule => setSchedules (schedules.filter (s => s.id !== schedule.id))

  const toggleScheduleDetailsModal = () => setShowScheduleDetailsModal (!showScheduleDetailsModal)
  const toggleEditBillModal = () => setShowEditBillModal (!showEditBillModal)

  return (
    <Container>
      <Navbar/>
      <FilterContainer>
        <ClearableInput
          placeholder='Pesquisar por cliente'
          value={query}
          onChange={e => setQuery (e.target.value)}
          onClear={() => {
            setQuery ('')
            setLoading (true)
            getSchedules (1, '')
          }}
          inputStyle={{boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
        />
        <Select
          name='status'
          placeholder='Status...'
          value={status}
          options={[
            {label: 'Não cancelados', value: 'not_canceled'},
            {label: 'Cancelados', value: 'canceled'},
            {label: 'Todos', value: 'all'}
          ]}
          onChange={(_, option) => {
            setLoading (true)
            setStatus (option)
          }}
          containerStyles={{boxShadow: '0px 1px 10px -6px', borderRadius: 15}}
        />
        <Select
          name='month'
          placeholder='Mês...'
          value={month}
          options={TimeWindow.getMonths ()}
          isClearable={true}
          onChange={(_, option) => {
            setLoading (true)
            setMonth (option)
            localStorage.setItem ('business_month_schedules', JSON.stringify (option))
          }}
          containerStyles={{boxShadow: '0px 1px 10px -6px', borderRadius: 15}}
        />
        <Select
          name='year'
          placeholder='Ano...'
          value={year}
          options={TimeWindow.getYears ('2021', `${DateTime.now ().year}`)}
          isClearable={true}
          onChange={(_, option) => {
            setLoading (true)
            setYear (option)
            localStorage.setItem ('business_year_schedules', JSON.stringify (option))
          }}
          containerStyles={{boxShadow: '0px 1px 10px -6px', borderRadius: 15}}
        />
        <Select
          name='professional'
          placeholder='Profissional...'
          value={professional}
          options={professionals}
          isClearable={true}
          onChange={(_, option) => {
            setLoading (true)
            setProfessional (option)
            localStorage.setItem ('professional_schedules', JSON.stringify (option))
          }}
          containerStyles={{boxShadow: '0px 1px 10px -6px', borderRadius: 15}}
        />
      </FilterContainer>
      {loading ? <Loading style={{marginTop: 10}}/> :
        <ScheduleList
          detailed
          schedules={schedules}
          totalSchedules={total}
          hasMore={!(lastPage && page > lastPage)}
          onScroll={() => getSchedules ()}
          onScheduleSelected={schedule => {
            setScheduleData (schedule)
            toggleScheduleDetailsModal ()
          }}
        />
      }
      {scheduleData && (
        <>
          <ScheduleDetailsModal
            visible={showScheduleDetailsModal}
            data={scheduleData}
            onEdit={schedule => {
              setScheduleData (schedule)
              handleEditSchedule (schedule)
            }}
            onDelete={handleDeleteSchedule}
            onClose={toggleScheduleDetailsModal}
            onOpenBill={bill => {
              setBillData (bill)
              setScheduleData ({...scheduleData, bill_id: bill.id})
              toggleEditBillModal ()
            }}
          />
          {billData && (
            <EditBillModal
              visible={showEditBillModal}
              data={billData}
              onClose={toggleEditBillModal}
              // onConfirm={handleEditSchedule}
            />
          )}
        </>
      )}
    </Container>
  )
}

export default Schedules

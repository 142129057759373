import React from 'react'
import BillBundle from './BillBundle'

import {
  Container,
  SubTitle,
  LabelRow
} from './styles'

function BillBundleList ({
  bill,
  billBundles,
  professionals,
  enableEditting = true,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  return billBundles.length > 0 && (
    <Container>
      <LabelRow enableEditting={enableEditting}>
        <SubTitle>Nome</SubTitle>
        <SubTitle>Profissional</SubTitle>
        <SubTitle>Qtd</SubTitle>
        <SubTitle>Preço</SubTitle>
        <SubTitle>Comissão (%)</SubTitle>
        {enableEditting && <SubTitle>Ações</SubTitle>}
      </LabelRow>
      {billBundles.map ((bundle, index) =>
        <BillBundle
          key={index}
          bill={bill}
          data={bundle}
          professionals={professionals}
          enableEditting={enableEditting}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </Container>
  )
}

export default BillBundleList

import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
`

export const Header = styled.div`
  padding: 12px 20px;
  background-color: #FFFFFF;
  flex-direction: row;
  justify-content: center;
`

export const InfoContainer = styled.div`
  display: flex;
  min-width: 480px;
  width: 640px;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 15px !important;
  margin-bottom: 10px;
`

export const DayContainer = styled(Link)`
  min-width: 920px;
  width: 980px;
  height: 40px;
  display: grid;
  grid-template-columns: ${({bonuses, discounts}) => {
    let numColumns = 4
    if (bonuses?.length > 0) numColumns++
    if (discounts?.length > 0) numColumns++
    return `150px repeat(${numColumns}, 1fr) 20px`
  }};
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 15px;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  padding: 8px 15px !important;
  margin-bottom: 10px;
  text-decoration: none;

  &:hover {
    background-color: #F7F7F7;
  }

  p {
    &:first-child {
      justify-self: flex-start;
    }
  }
`

export const ListContainer = styled(Container)`
  padding: 15px 0px 30px;
`

export const RegisterColumn = styled(Container)`
  width: 50%;
  height: 100%;
  padding: 15px 20px 0px 0px;
  align-items: flex-start;
  border-left: 1px solid #E0E0E0;

  &:first-child {
    border-left: 0px;
    padding-right: 0px;
  }
`

export const Info = styled(InfoContainer)`
  margin: 0px;
  padding: 0px !important;
  border: 0px;
  box-shadow: none;
  width: 100%;
`

export const FilterContainer = styled(Info)`
  display: grid;
  grid-template-columns: 9fr 9fr 9fr 10fr;
  column-gap: 10px;
  min-width: 840px;
  width: 920px;
  height: auto;
  margin-bottom: 10px;
`

export const ErrorContainer = styled.div`
  padding: 9px;
  margin: 15px 0px;
  width: 100%;
  border-radius: 15px;
  align-items: center;
  background-color: #FF3939;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

export const InfoRow = styled(Row)`
  width: 100%;
`

export const LabelRow = styled.div`
  display: grid;
  grid-template-columns: ${({bonuses, discounts}) => {
    let numColumns = 4
    if (bonuses?.length > 0) numColumns++
    if (discounts?.length > 0) numColumns++
    return `150px repeat(${numColumns}, 1fr) 20px`
  }};
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin-bottom: 7px;
  padding: 0px 15px;
  min-width: 920px;
  width: 980px;

  p {
    &:first-child {
      justify-self: flex-start;
      margin-left: 25px;
    }
  }
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
`

export const Title = styled(InfoText)`
  font-size: 16px;
  font-weight: bold;
  padding: 0px;
`

export const SubTitle = styled(Title)`
  font-size: 14px;
`

export const LinkButton = styled(Link)`
  color: #35A7FF;
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    color: #35A7FF88;
  }
`

export const Button = styled.button`
  color: #FFFFFF;
  background-color: #606060;
  font-size: 14px;
  height: 38px;
  margin: 0px 0px 8px;
  text-decoration: none;
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  &:hover {
    background-color: #404040;
  }
`

export const TabButton = styled.button`
  color: ${({ selected }) => selected ? '#FFFFFF' : "#000000"};
  background-color: ${({ selected, color }) => selected ? color : '#FFFFFF'};
  font-size: 14px;
  font-weight: bold;
  width: 170px;
  height: 38px;
  margin: 0px 0px 10px;
  border: 0px;
  box-shadow: 0px 2px 10px -6px #252525;
  text-decoration: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => props.color};
  }
`

export const SearchButton = styled.button`
  color: #FFFFFF;
  background-color: #606060;
  height: 40px;
  margin: 0px;
  text-decoration: none;
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  &:hover {
    background-color: #404040;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  border: 0px;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 15px;
  text-align: center;
  padding-right: ${({type}) => type === 'date' ? '9px' : '0px'};

  &::placeholder {
    color: #BBBBBB
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const MoneyTextMask = styled(NumericFormat).attrs (props => ({
  value: props.value.toFixed (2).replace ('.', ','),
  thousandSeparator: '.',
  decimalSeparator: ',',
  displayType: 'text',
  prefix: 'R$ '
}))`
  color: ${({ value }) => parseFloat (value) >= 0 ? '#44CF6C' : '#FF3939'};
  font-size: 16px;
  font-weight: bold;
  line-height: 14px;
  margin-bottom: 0px;
  padding: 0px;
`

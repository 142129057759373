import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import BusinessBillList from '../../../components/Lists/BusinessBillList'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  modalStyle,
  Container,
  InfoColumn,
  BillColumn,
  Header,
  Row,
  Title,
  SubTitle,
  InfoText,
  MoneyTextMask,
  CloseButton
} from './styles'

function CustomerServiceDetailsModal ({
  visible,
  customer,
  service,
  onClose: handleClose
}) {
  const { bundle: businessBundle, bill } = service
  const businessBundleService = businessBundle.services.find (s => s.id === service.id)
  const servicePrice = businessBundleService.discount_price
  const [loading, setLoading] = useState (true)
  const [billBundle, setBillBundle] = useState (null)
  const [usedServiceBills, setUsedServiceBills] = useState ([])
  const [serviceDiscountPrice, setServiceDiscountPrice] = useState (0)
  const token = AuthService.getToken ()
  const user = AuthService.getUser ()
  const isBusiness = AuthService.isBusiness ()
  const businessId = isBusiness ? user.id : user.business_id

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    if (visible) getData ()
  }, [visible])

  const getData = async () => {
    try {
      const { data: billBundles } = await cutileiApi.get (
        `/customers/${customer.id}/bundles?bundle_id=${businessBundle.id}`
        + (bill ? `&bill_id=${bill?.id}` : ''),
        requestConfig
      )

      const { data: usedServiceBills } = await cutileiApi.get (
        `/business_bills?business_id=${businessId}&customer_id=${customer.id}&deleted=false`
        + `&customer_services_id=${service.customer_services_id}&detailed=true`,
        requestConfig
      )

      setUsedServiceBills (usedServiceBills)
      setBillBundle (billBundles[0])
      const bundleDiscontPrice = billBundles[0]?.custom_price ?? businessBundle?.price

      setServiceDiscountPrice ((
        ((servicePrice * businessBundleService.amount) / businessBundle.price) * bundleDiscontPrice
      ) / businessBundleService.amount)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      {loading ? <Loading/> : (
        <Container>
          <Header>
            <Title>Detalhes do serviço</Title>
            <CloseButton onClick={handleClose}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </CloseButton>
          </Header>
          <Row style={{alignItems: 'flex-start'}}>
            <InfoColumn>
              <Row>
                <InfoText>Nome:</InfoText>
                <SubTitle>{service.name}</SubTitle>
              </Row>
              <Row>
                <InfoText>Pacote:</InfoText>
                <SubTitle>{businessBundle.name}</SubTitle>
              </Row>
              <Row>
                <InfoText>Validade:</InfoText>
                <SubTitle>
                  {service.expires_at
                    ? DateTime.fromISO (service.expires_at).toFormat ('dd/MM/yyyy')
                    : 'Indeterminado'
                  }
                </SubTitle>
              </Row>
              <Row>
                <InfoText>Preço original do pacote:</InfoText>
                <MoneyTextMask value={businessBundle?.price}/>
              </Row>
              <Row>
                <InfoText>Preço pago pelo pacote:</InfoText>
                {billBundle ? (
                  <MoneyTextMask value={billBundle?.custom_price}/>
                ) : (
                  <MoneyTextMask value={businessBundle?.price}/>
                )}
              </Row>
              <Row>
                <InfoText>Data da compra:</InfoText>
                <SubTitle>
                  {service.created_at 
                    ? DateTime.fromISO (service.created_at).toFormat ('dd/MM/yyyy')
                    : '-'
                  }
                </SubTitle>
              </Row>
            </InfoColumn>
            <InfoColumn>
              <Row>
                <InfoText>Serviço s/ desconto:</InfoText>
                <MoneyTextMask value={servicePrice}/>
              </Row>
              <Row>
                <InfoText>Serviço c/ desconto:</InfoText>
                <MoneyTextMask value={serviceDiscountPrice}/>
              </Row>
              <Row>
                <InfoText>Desconto:</InfoText>
                <SubTitle>
                  {Math.round ((1 - serviceDiscountPrice / servicePrice) * 100)}%
                </SubTitle>
              </Row>
              <Row>
                <InfoText>Qtd.:</InfoText>
                <SubTitle>{service.total_amount}</SubTitle>
              </Row>
              <Row>
                <InfoText>Qtd. utilizada:</InfoText>
                <SubTitle>{service.total_amount - service.available_amount}</SubTitle>
              </Row>
              <Row>
                <InfoText>Qtd. disponível:</InfoText>
                <SubTitle>{service.available_amount}</SubTitle>
              </Row>
            </InfoColumn>
          </Row>
          {usedServiceBills.length > 0 && (
            <BillColumn>
              <SubTitle style={{paddingBlock: 12}}>
                Comandas dos serviços utilizados
              </SubTitle>
              <BusinessBillList
                bills={usedServiceBills}
                showTotalBills={false}
                onEdit={bill => setUsedServiceBills (usedServiceBills.map (b => b.id === bill.id ? {...bill} : b))}
                onDelete={bill => setUsedServiceBills (usedServiceBills.filter (b => b.id !== bill.id))}
                containerStyle={{padding: 0}}
              />
            </BillColumn>
          )}
        </Container>
      )}
    </Modal>
  )
}

export default CustomerServiceDetailsModal

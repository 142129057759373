export default class General {
  static groupBy (objectArray, prop) {
    return Object.values (objectArray.reduce ((acc, obj) => {
      let key = obj[prop]
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push (obj)
      return acc
    }, {}))
  }
}
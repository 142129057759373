import React from 'react'
import Service from './Service'

import {
  Container,
  SubTitle,
  LabelRow
} from './styles'

function ServiceList ({ services }) {
  return (
    <Container>
      {services.length > 0 ? (
        <>
          <SubTitle>
            {services.length + ' ' + (services.length === 1 ? 'Resultado' : 'Resultados')}
          </SubTitle>
          <LabelRow>
            <SubTitle>Nome</SubTitle>
            <SubTitle>Categoria</SubTitle>
            <SubTitle>Preço</SubTitle>
            <SubTitle style={{justifySelf: 'flex-end'}}>Status</SubTitle>
          </LabelRow>
          {services.map (service =>
            <Service key={service.id} data={service}/>
          )}
        </>
      ) : (
        <SubTitle>
          0 resultados
        </SubTitle>
      )}
    </Container>
  )
}

export default ServiceList

import React, { useState } from 'react'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import Attachment from './Attachment'
import FileInput from '../../Inputs/FileInput'
import { Formik } from 'formik'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'

import {
  Container,
  Row,
  SubTitle,
  InfoText,
  Button,
  ButtonText,
  FileContainer,
  ErrorContainer
} from './styles'

function AttachmentList ({
  attachments,
  customerID,
  onDelete: handleDelete,
  onAttachmentAdded: handleAttachmentAdded
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const token = AuthService.getToken ()
  const user = AuthService.getUser ()
  const isBusiness = AuthService.isBusiness ()
  const businessId = isBusiness ? user.id : user.business_id

  return (
    <Container>
      <Formik
        // validationSchema={paymentValidator}
        initialValues={{
          'attachment': null,
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const { attachment } = values
            const formData = new FormData ()

            formData.append ('attachment', attachment)
            formData.append ('customerId', customerID)

            const { data: addedAttachment } = await cutileiApi.post (
              `/businesses/${businessId}/attachments`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${token}`
                }
              }   
            )

            setSubmitting (false)
            resetForm ()
            handleAttachmentAdded (addedAttachment)
          } catch (error) {
            if (error.response.data) setErrorMessage (error.response.data.message)
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {({
          values,
          isSubmitting,
          setFieldValue,
          handleSubmit
        }) => (
          <Row>
            <FileInput
              label={values.attachment ? 'Trocar arquivo' : 'Adicionar anexo'}
              onFileSelected={file => setFieldValue ('attachment', file)}
            />
            {values.attachment && (
              <FileContainer>
                <FA.FaPaperclip size={16} color='#000000' style={{marginRight: -6}}/>
                <SubTitle>
                  {values.attachment.name}
                </SubTitle>
                <Button
                  type='submit'
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  style={{width: 100}}
                >
                  {isSubmitting ? <ButtonLoading/> : 'Confirmar'}
                </Button>
              </FileContainer>
            )}
          </Row>
        )}
      </Formik>
      {errorMessage && (
        <ErrorContainer>
          <ButtonText>{errorMessage}</ButtonText>
        </ErrorContainer>
      )}
      {attachments.length > 0 ? attachments.map (attachment =>
        <Attachment
          key={attachment.id}
          data={attachment}
          onDelete={handleDelete}
        />
      ) : (
        <InfoText>Este cliente ainda não possui anexos</InfoText>
      )}
    </Container>
  )
}

export default AttachmentList

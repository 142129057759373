import styled from 'styled-components'

export const Container = styled.div`
  min-width: 480px;
  width: 640px;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 16px;
  text-align: center;
`

export const Title = styled(InfoText)`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
`

export const SubTitle = styled(Title)`
  font-size: 16px;
  margin-bottom: 10px;
`

import React, { useState } from 'react'
import BillSchedule from './BillSchedule'

import {
  Container,
  SubTitle,
  InfoText,
  LabelRow
} from './styles'

function BillScheduleList ({
  schedules,
  professionals,
  customerServices = [],
  showFreeItemsValue = false,
  enableEditting = true,
  services,
  onEdit,
  onDelete: handleDelete,
  onRemoveFromBill: handleRemoveFromBill
}) {
  const [editting, setEditting] = useState (false)

  const handleEdit = schedule => {
    setEditting (false)
    onEdit (schedule)
  }

  return (
    <Container>
      {schedules.length > 0 ? (
        <>
          <LabelRow editting={editting} enableEditting={enableEditting}>
            <SubTitle>Serviço</SubTitle>
            <SubTitle>Profissional</SubTitle>
            <SubTitle>Data</SubTitle>
            <SubTitle>Horário</SubTitle>
            <SubTitle>Comissão (%)</SubTitle>
            <SubTitle>Preço</SubTitle>
            {enableEditting && <SubTitle>Ações</SubTitle>}
          </LabelRow>
          {schedules.map (schedule =>
            <BillSchedule
              key={schedule.id}
              data={schedule}
              professionals={professionals}
              services={services}
              isFree={customerServices.some (s =>s.id === schedule.service.id) || schedule.bundle_id}
              showFreeItemsValue={showFreeItemsValue}
              enableEditting={enableEditting}
              onToggleEdit={setEditting}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onRemoveFromBill={handleRemoveFromBill}
            />
          )}
        </>
      ) : (
        <InfoText>Nenhum agendamento encontrado</InfoText>
      )}
    </Container>
  )
}

export default BillScheduleList

import React, { useState, useEffect } from 'react'
import cutileiApi, { apiUrl } from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import AddCashRegisterModal from '../AddCashRegisterModal'
import CashRegister from '../../../components/CashRegister'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'
import io from 'socket.io-client'

import {
  ListContainer,
  Row,
  Button
} from '../styles'

function CashRegisters () {
  const [loading, setLoading] = useState (true)
  const [cashRegisters, setCashRegisters] = useState ([])
  const [bankAccounts, setBanksAccounts] = useState ([])
  const [cardFlags, setCardFlags] = useState ([])
  const [showAddCashRegisterModal, setShowAddCashRegisterModal] = useState (false)
  const business = AuthService.getUser ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  useEffect (() => {
    const socket = io (apiUrl)

    socket.on (`${business.id}_cash_register_created`, handleCashRegisterCreated)
    socket.on (`${business.id}_cash_register_closed`, handleCashRegisterClosed)

    return () => {socket.disconnect ()}
  }, [cashRegisters])

  const getData = async () => {
    try {
      setLoading (true)

      const { data: cashRegisters } = await cutileiApi.get (
        `/cash_registers?open=true`, requestConfig
      )

      const { data: bankAccounts } = await cutileiApi.get (
        `/businesses/${business.id}/bank_accounts`, requestConfig
      )

      const { data: cardFlags } = await cutileiApi.get (
        '/card_flags', requestConfig
      )

      setCashRegisters (cashRegisters)
      setBanksAccounts (bankAccounts)
      setCardFlags (cardFlags)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const handleCashRegisterCreated = cashRegister => setCashRegisters ([cashRegister, ...cashRegisters])

  const handleCashRegisterClosed = async cashRegister => {
    setCashRegisters (cashRegisters.filter (cr => cr.id !== cashRegister.id))
    await getData ()
  }

  const toggleAddCashRegisterModal = () => setShowAddCashRegisterModal (!showAddCashRegisterModal)

  return (
    <ListContainer>
      <Row>
        <Button style={{marginBottom: 15}} onClick={toggleAddCashRegisterModal}>
          <FA.FaPlus size={12} style={{marginTop: 2, marginRight: 7}}/>
          Abrir caixa
        </Button>
        <AddCashRegisterModal
          visible={showAddCashRegisterModal}
          onConfirm={handleCashRegisterCreated}
          onClose={toggleAddCashRegisterModal}
        />
      </Row>
      {loading ? <Loading/> : cashRegisters.length > 0 && cashRegisters.map (cashRegister => (
        <CashRegister
          key={cashRegister.id}
          data={cashRegister}
          cardFlags={cardFlags}
          bankAccounts={bankAccounts}
          onCloseCashRegister={handleCashRegisterClosed}
        />
      ))}
    </ListContainer>
  )
}

export default CashRegisters
